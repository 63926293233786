// extracted by mini-css-extract-plugin
export var blurb = "HighlightCard-module--blurb--IiXi8";
export var fourtynineB = "HighlightCard-module--fourtynine-b--jUlfp";
export var image = "HighlightCard-module--image--QXfWw";
export var inputLabel = "HighlightCard-module--input-label--HM3Sd";
export var large = "HighlightCard-module--large--uiQLW";
export var panel = "HighlightCard-module--panel--ZwVW5";
export var seventysixB = "HighlightCard-module--seventysix-b--ZUruW";
export var sixteenB = "HighlightCard-module--sixteen-b--TTLK+";
export var sixteenR = "HighlightCard-module--sixteen-r--mJcgh";
export var small = "HighlightCard-module--small--92M-T";
export var thirteenMK = "HighlightCard-module--thirteen-m-k--cBK6l";
export var thirtynineB = "HighlightCard-module--thirtynine-b--oa7Us";
export var thirtyoneB = "HighlightCard-module--thirtyone-b--f+shb";
export var title = "HighlightCard-module--title--mxK4H";
export var twentyB = "HighlightCard-module--twenty-b--6xZRA";
export var twentyR = "HighlightCard-module--twenty-r--G5jo0";
export var twentyfiveB = "HighlightCard-module--twentyfive-b--Xl6H8";