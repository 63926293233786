import * as React from 'react'
import { PageProps, Link } from 'gatsby'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { careersComponents, getSliceContextFromPageData } from '../slices'
import { PageHero } from '../components/PageHero'
import { SEOSocialMeta } from '../components/SEOSocialMeta'
import { Stat } from '../components/Stat'

import * as styles from './careers.module.scss'

type CareersPageProps = {
  data: Queries.CareersPageQuery
  location: {
    state?: {
      jobLocation: string
    }
  }
}

const CareersPage = ({ location, data }: CareersPageProps) => {
  if (!data || !data.prismicCareersPage) return null
  const doc = data.prismicCareersPage.data
  const bgStart = doc.slice_background_starting_color
  console.log(doc)
  return (
    <Layout>
      <SEOSocialMeta
        facebook={{
          description: doc.facebook_description,
          image: doc.facebook_image?.url,
          title: doc.facebook_title,
        }}
        seo={{
          description: doc.seo_description,
          title: doc.seo_title,
        }}
        twitter={{
          description: doc.twitter_description,
          image: doc.twitter_image?.url,
          title: doc.twitter_title,
        }}
      />
      <PageHero
        isDarker={bgStart ?? false}
        label={doc.page_label ?? 'Careers'}
        title={doc.title ?? 'Join the team'}
        blurb={doc.blurb?.text ?? undefined}
        centerComponent={
          doc.stats != null ? (
            <div className={styles.stats}>
              {doc.stats.map((entry, index) => (
                <Stat
                  key={index}
                  value={
                    entry?.pull_value_from_job_listings
                      ? `${data.allPrismicJobListing.totalCount}`
                      : entry?.value ?? ''
                  }
                  label={entry?.label ?? ''}
                />
              ))}
              {doc.source && (
                <>
                  <div className={styles.largeOnly}></div>
                  <div className={styles.largeOnly}></div>
                  <div className={styles.statSource}>Source: {doc.source}</div>
                </>
              )}
            </div>
          ) : undefined
        }
        ctaComponent={
          doc.scroll_to_listings ? (
            <button
              className={styles.button}
              onClick={() => {
                document
                  .getElementById('listings')
                  ?.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              {doc.cta_text}
            </button>
          ) : doc.cta_link?.url ? (
            <Link className={styles.button} to={doc.cta_link?.url}>
              {doc.cta_text}
            </Link>
          ) : null
        }
      />
      <SliceZone
        slices={doc.body}
        components={careersComponents}
        context={{
          ...getSliceContextFromPageData(doc),
          jobLocation: location.state?.jobLocation,
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  query CareersPage {
    prismicCareersPage {
      _previewable
      data {
        ...CareersPageDataSEOSocialMeta
        scroll_to_listings
        source
        page_label
        blurb {
          text
        }
        cta_text
        stats {
          label
          value
          pull_value_from_job_listings
        }
        title
        cta_link {
          id
          url
          target
          link_type
        }
        slice_background_starting_color
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...CareersPageDataBodyBenefitsAndCulture
          ...CareersPageDataBodyJobListings
          ...CareersPageDataBodyValuesHighlight
          ...CareersPageDataBodyArticleList
        }
      }
    }
    allPrismicJobListing {
      totalCount
    }
  }
`

export default withPrismicPreview(
  CareersPage as ({
    location,
    data,
  }: PageProps<Queries.CareersPageQuery>) => JSX.Element | null
)
