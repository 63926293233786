// extracted by mini-css-extract-plugin
export var blurb = "StatsHighlighter-module--blurb--YmHV+";
export var button = "StatsHighlighter-module--button--nAAVD";
export var center = "StatsHighlighter-module--center--3xpl4";
export var contentContainer = "StatsHighlighter-module--content-container--ydcL1";
export var disabled = "StatsHighlighter-module--disabled--cw45-";
export var fourtynineB = "StatsHighlighter-module--fourtynine-b--ZkGo+";
export var highlighter = "StatsHighlighter-module--highlighter--eCmCP";
export var inputLabel = "StatsHighlighter-module--input-label--FHdx7";
export var selected = "StatsHighlighter-module--selected--v4cHl";
export var seventysixB = "StatsHighlighter-module--seventysix-b--70fDX";
export var sixteenB = "StatsHighlighter-module--sixteen-b--NH9xD";
export var sixteenR = "StatsHighlighter-module--sixteen-r--Xku2m";
export var source = "StatsHighlighter-module--source--K3w0F";
export var statsContainer = "StatsHighlighter-module--statsContainer--QGk7W";
export var statsSliceContainer = "StatsHighlighter-module--statsSliceContainer--ljcTY";
export var textContainer = "StatsHighlighter-module--textContainer--7JPUr";
export var thirteenMK = "StatsHighlighter-module--thirteen-m-k--WgcU9";
export var thirtynineB = "StatsHighlighter-module--thirtynine-b--Z05VM";
export var thirtyoneB = "StatsHighlighter-module--thirtyone-b--yXNJd";
export var title = "StatsHighlighter-module--title--SsOzK";
export var twentyB = "StatsHighlighter-module--twenty-b--onf55";
export var twentyR = "StatsHighlighter-module--twenty-r--uIj+V";
export var twentyfiveB = "StatsHighlighter-module--twentyfive-b--V45L5";
export var variant = "StatsHighlighter-module--variant--6fW83";