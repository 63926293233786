// extracted by mini-css-extract-plugin
export var fourtynineB = "brands-module--fourtynine-b--8YycZ";
export var inputLabel = "brands-module--input-label--QlYPV";
export var pageLabel = "brands-module--pageLabel--L8uP1";
export var seventysixB = "brands-module--seventysix-b---oKWR";
export var sixteenB = "brands-module--sixteen-b--xAyIh";
export var sixteenR = "brands-module--sixteen-r--7nknj";
export var thirteenMK = "brands-module--thirteen-m-k--eyuRu";
export var thirtynineB = "brands-module--thirtynine-b--u2FQV";
export var thirtyoneB = "brands-module--thirtyone-b--s+-bi";
export var twentyB = "brands-module--twenty-b--UEcIH";
export var twentyR = "brands-module--twenty-r--WSME4";
export var twentyfiveB = "brands-module--twentyfive-b--bc7IK";