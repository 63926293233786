// extracted by mini-css-extract-plugin
export var featured = "ArticleTile-module--featured--25zIA";
export var fourtynineB = "ArticleTile-module--fourtynine-b--nMPGL";
export var image = "ArticleTile-module--image--HPJVl";
export var inputLabel = "ArticleTile-module--input-label--CAtJW";
export var seventysixB = "ArticleTile-module--seventysix-b--amazI";
export var sixteenB = "ArticleTile-module--sixteen-b--GJGRk";
export var sixteenR = "ArticleTile-module--sixteen-r--L63Nx";
export var thirteenMK = "ArticleTile-module--thirteen-m-k--6HB7u";
export var thirtynineB = "ArticleTile-module--thirtynine-b--scO84";
export var thirtyoneB = "ArticleTile-module--thirtyone-b--5MXqX";
export var title = "ArticleTile-module--title--Md4v3";
export var twentyB = "ArticleTile-module--twenty-b--JNWsc";
export var twentyR = "ArticleTile-module--twenty-r--hNhpv";
export var twentyfiveB = "ArticleTile-module--twentyfive-b--SAkDr";
export var wrapper = "ArticleTile-module--wrapper--qApdx";