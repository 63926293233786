// extracted by mini-css-extract-plugin
export var clientsGrid = "ClientsGrid-module--clientsGrid--n+Tzq";
export var fourtynineB = "ClientsGrid-module--fourtynine-b--vk+HI";
export var hiddenMobile = "ClientsGrid-module--hiddenMobile--xYXf9";
export var img = "ClientsGrid-module--img--Sej2B";
export var inputLabel = "ClientsGrid-module--input-label--DLoSB";
export var seventysixB = "ClientsGrid-module--seventysix-b--9OIYI";
export var sixteenB = "ClientsGrid-module--sixteen-b--oGTS3";
export var sixteenR = "ClientsGrid-module--sixteen-r--98Hr1";
export var thirteenMK = "ClientsGrid-module--thirteen-m-k--T6zYJ";
export var thirtynineB = "ClientsGrid-module--thirtynine-b--kIE-V";
export var thirtyoneB = "ClientsGrid-module--thirtyone-b--AhYVw";
export var twentyB = "ClientsGrid-module--twenty-b--rJzoC";
export var twentyR = "ClientsGrid-module--twenty-r--CNBgI";
export var twentyfiveB = "ClientsGrid-module--twentyfive-b--00T71";