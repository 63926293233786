import * as React from 'react'
import * as styles from './Share.module.scss'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton
} from 'react-share'

type ShareProps = {
  title?: string,
  link: string
}

export const Share = ({link, title}: ShareProps) => {
  title = title !== undefined ? title : ""
  const [iconStatus, setIconStatus] = React.useState<'share' | 'close' | 'done'>('share');
  const toggle = (i: 'share' | 'close' | 'done') => {
    if (navigator.share) {
      navigator.share({
        title: title,
        url: link
      })
      return
    }
    if (i === 'done') {
      setTimeout(() => toggle('share'), 1000);
    }
    setIconStatus(i);
  };
  const clipboard = () => navigator.clipboard.writeText(link);

  const open: React.LegacyRef<HTMLDivElement> = React.createRef();
  const close: React.LegacyRef<HTMLDivElement> = React.createRef();
  const copy: React.LegacyRef<HTMLDivElement> = React.createRef();
  const menu = (e: HTMLElement, k: KeyboardEvent, action: 'close' | 'share') => {
    if (k.key === 'Enter') {
      toggle(action)
    }
  }
  React.useEffect(() => {
    // Make share open/close/copy to clipboard accessible via keyboard
    if (open.current) {
      open.current.addEventListener('keydown', (k: KeyboardEvent) => {
        menu(open.current!, k, 'close')
      })
    }
    if (close.current) {
      close.current.addEventListener('keydown', (k: KeyboardEvent) => {
        menu(close.current!, k, 'share')
      })
    }
    if (copy.current) {
      copy.current.addEventListener('keydown', (k: KeyboardEvent) => {
        if (k.key === 'Enter') {
          clipboard();
          toggle('done');
        }
      })
    }
  })

  return(
    <div className={`${styles.shareWrapper} ${iconStatus === 'close' ? styles.open : styles.closed}`}>
		  <div className={`${styles.shareContainer} ${iconStatus === 'close' ? styles.open : styles.closed}`}>
        <div className={styles.shareIcon}>
          <i
            className={`icon-share ${styles.dark} ${styles.shareNav} ${iconStatus === 'share' ? styles.visible : styles.hide}`}
            onClick={() => toggle('close')}
            aria-hidden={iconStatus !== 'share'}
            tabIndex={iconStatus === 'share' ? 0 : -1}
            role={"button"}
            ref={open}
          >
            share
          </i>
          <i
            className={`icon-close ${styles.dark} ${styles.shareNav} ${iconStatus === 'close' ? styles.visible : styles.hide}`}
            onClick={() => toggle('share')}
            aria-hidden={iconStatus !== 'close'}
            tabIndex={iconStatus === 'close' ? 0 : -1}
            role={"button"}
            ref={close}
          >
            close
          </i>
          <i
            className={`icon-check ${styles.dark} ${styles.shareNav} ${iconStatus === 'done' ? styles.visible : styles.hide}`}
            aria-hidden={iconStatus !== 'done'}
          >
            done
          </i>
        </div>
        <div className={styles.shareMenu}>
          <FacebookShareButton
            url={link}
            title={title}
            onClick={() => toggle('done')}
          >
            <i className="icon-facebook">
              Facebook
            </i>
          </FacebookShareButton>
          <TwitterShareButton
            url={link}
            title={title}
            onClick={() => toggle('done')}
          >
            <i className="icon-twitter">
              Twitter
            </i>
          </TwitterShareButton>
          <LinkedinShareButton
            url={link}
            title={title}
            onClick={() => toggle('done')}
          >
            <i className="icon-linkedin">
              LinkedIn
            </i>
          </LinkedinShareButton>
          <i
            onClick={() => {
              clipboard();
              toggle('done');
            }}
            tabIndex={0}
            role={"button"}
            ref={copy}
          >
            <i
              className={"icon-copy"}
            >
              Copy to Clipboard
            </i>
          </i>
        </div>
      </div>
    </div>
  )
}