import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { SiteHeader } from './SiteHeader'
import { SiteFooter } from './SiteFooter'
import { ScrollToTop } from './ScrollToTop'

import '../styles/styles.scss'

import * as skiptocontentStyles from './SkipToContent.module.scss'

type LayoutProps = {
  children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:site_name" content="GAMURS Group" />
      </Helmet>
      <a className={skiptocontentStyles.skipToContent} href="#content">Skip to Content</a>
      <SiteHeader/>
      <main id="content" tabIndex={-1}>{children}</main>
      <SiteFooter/>
      <ScrollToTop/>
    </>
  )
}
