import * as React from 'react'

import classNames from 'classnames'

import * as styles from './ScrollToTop.module.scss'

import ScrollToTopIcon from '../images/icons/icon-scroll-to-top.svg'

export const ScrollToTop = () => {
  const sTTRef: React.LegacyRef<HTMLDivElement> = React.createRef()

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  React.useEffect(() => {
    if (sTTRef.current) {
      if (document.body.clientHeight < 2 * window.innerHeight) {
        sTTRef.current.classList.remove(styles.hide)
      }

      var prevScrollpos = window.pageYOffset
      window.addEventListener('scroll', function () {
        var currentScrollPos = window.pageYOffset
        // if scroll up
        if (prevScrollpos > currentScrollPos && currentScrollPos !== 0) {
          if (sTTRef.current) {
            sTTRef.current.classList.add(styles.show)
          }
          //if scroll down
        } else {
          if (sTTRef.current) {
            sTTRef.current.classList.remove(styles.show)
          }
        }
        prevScrollpos = currentScrollPos
      })
    }
  }, [sTTRef])

  return (
    <div
      className={classNames(styles.scrolltotop, styles.hide)}
      onClick={scrollToTop}
      ref={sTTRef}
    >
      <img
        src={ScrollToTopIcon}
        role="presentation"
        alt="Scroll To Top Button"
      />
    </div>
  )
}
