import * as React from 'react'

import { graphql } from 'gatsby'

import { getSliceBackgroundColor, SlicePageDataContext } from './index'
import { EmployeeProfile } from '../components/EmployeeProfile'

import * as styles from './TeamSlice.module.scss'

interface TeamSliceProps {
  slice: Queries.AboutPageDataBodyPartyFragment
  index: number
  context: SlicePageDataContext
}

export const TeamSlice = ({ slice, index, context }: TeamSliceProps) => {
  const sliceBackgroundColor = getSliceBackgroundColor(
    context.bgStart,
    context.otherSlices,
    index
  )
  return (
    <section
      className={`${styles.teamSlice} ${
        sliceBackgroundColor === 'darker'
          ? 'backgroundDarker'
          : 'backgroundDark'
      }`}
    >
      <div className={styles.container}>
        <h1 className={styles.title}>{slice.primary.title}</h1>
        <div className={styles.party}>
          {slice.items.map((item, index) => {
            const partyMember = (
              item.member?.document as Queries.PrismicEmployee
            ).data
            return (
              <EmployeeProfile
                key={index}
                name={partyMember.name ?? ''}
                photoUrl={partyMember.profile?.url ?? ''}
                photoAlt={partyMember.profile?.alt ?? ''}
                photoGatsbyImageData={partyMember.profile?.gatsbyImageData}
                title={partyMember.title ?? ''}
                links={partyMember.social_links?.map((link) => ({
                  website: link?.website ?? '',
                  url: link?.link?.url ?? '',
                }))}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment AboutPageDataBodyParty on PrismicAboutPageDataBodyParty {
    slice_type
    slice_label
    primary {
      title
    }
    items {
      member {
        document {
          ... on PrismicEmployee {
            id
            data {
              name
              profile {
                gatsbyImageData(placeholder: BLURRED, height: 300, width: 300)
                url
                alt
              }
              social_links {
                link {
                  url
                }
                website
              }
              title
            }
          }
        }
      }
    }
  }
  fragment GenericPageDataBodyParty on PrismicGenericPageDataBodyParty {
    slice_type
    slice_label
    primary {
      title
    }
    items {
      member {
        document {
          ... on PrismicEmployee {
            id
            data {
              name
              profile {
                gatsbyImageData(placeholder: BLURRED, height: 300, width: 300)
                url
                alt
              }
              social_links {
                link {
                  url
                }
                website
              }
              title
            }
          }
        }
      }
    }
  }
`
