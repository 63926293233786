import * as React from 'react'

import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone, PrismicRichText } from '@prismicio/react'

import { ArticleList } from '../components/ArticleList'
import { Layout } from '../components/Layout'
import { SEOSocialMeta } from '../components/SEOSocialMeta'
import { Share } from '../components/Share'

import { articleComponents } from '../slices'
import { Highlights } from '../slices/Highlights'

import * as styles from './Article.module.scss'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import MikeAvatar from '../images/mike/mike-dp.svg'
import { checkImageIsSVG } from '../utils/urlUtils'

const BlogArticle = ({ data }: PageProps<Queries.BlogPostQuery>) => {
  if (!data?.prismicArticle) return null
  const doc = data.prismicArticle.data

  const extractArticle = (articleNode: any) => ({
    featuredImage: articleNode.data.featured_image,
    slug: articleNode.uid,
    title: articleNode.data.title.text,
  })

  const related = [
    ...data.articleCategoryTag.nodes.map(extractArticle),
    ...data.articleTagOnly.nodes.map(extractArticle),
    ...data.articleCategoryOnly.nodes.map(extractArticle),
    ...data.articleRecent.nodes.map(extractArticle),
  ].splice(0, 3)

  const date = doc.publish_date
    ? doc.publish_date
    : new Date(data.prismicArticle.first_publication_date).toLocaleDateString(
        undefined,
        {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }
      )

  const datetime = doc.publish_date
    ? new Date(doc.publish_date).toLocaleString('en-CA').split(',')[0]
    : data.prismicArticle.first_publication_date.split('T')[0]

  React.useEffect(() => {
    document.addEventListener('readystatechange', (e) => {
      if (
        e.target !== null &&
        (e.target as Document).readyState === 'complete'
      ) {
        Array.from(document.querySelectorAll('blockquote')).map((e) => {
          let script = document.createElement('script')
          if (e.nextElementSibling !== null) {
            script.src = (e.nextElementSibling as HTMLIFrameElement).src
            document.head.appendChild(script)
          }
        })
      }
    })
  })

  const back = () => {
    if (document.referrer) {
      if (new URL(document.referrer).host === window.location.host) {
        if (window.history.length > 1) {
          window.history.back()
        } else {
          window.location.href = '/blog'      
        }
        return
      }
    }
    window.location.href = '/blog'
  }

  return (
    <Layout>
      <SEOSocialMeta
        facebook={{
          description: doc.facebook_description ?? doc.blurb?.text,
          image: doc.facebook_image?.url ?? doc.featured_image?.url,
          title: doc.facebook_title ?? doc.title?.text,
        }}
        seo={{
          description: doc.seo_description ?? doc.blurb?.text,
          title: `${doc.seo_title ?? doc.title?.text} | GAMURS Group`,
        }}
        twitter={{
          description: doc.twitter_description ?? doc.blurb?.text,
          image: doc.twitter_image?.url ?? doc.featured_image?.url,
          title: doc.twitter_title ?? doc.title?.text,
        }}
      />
      <article className={styles.article}>
        <div className={styles.articleContainer}>
          <header className={styles.articleHeader}>
            <div className={styles.back} onClick={back}>
              <i className="icon-back">
                <div>BACK</div>
              </i>
            </div>
            <h1>{doc.title?.text ?? ''}</h1>
            <div className={styles.postMeta}>
              {doc.author ? (
                doc.author.document ? (
                  <div className={styles.author}>
                    <div className={styles.authorImage}>
                      {Object.keys(doc.author?.document).length !== 0 &&
                        (checkImageIsSVG(
                          (doc.author?.document as Queries.PrismicEmployee).data
                            ?.profile?.url
                        ) ? (
                          <img
                            src={
                              (doc.author?.document as Queries.PrismicEmployee)
                                .data?.profile?.url ?? ''
                            }
                            alt={
                              (doc.author?.document as Queries.PrismicEmployee)
                                .data?.profile?.alt ?? 'Author Image'
                            }
                          />
                        ) : (
                          <GatsbyImage
                            image={
                              (doc.author?.document as Queries.PrismicEmployee)
                                .data.profile
                                ?.gatsbyImageData as unknown as IGatsbyImageData
                            }
                            alt={
                              (doc.author?.document as Queries.PrismicEmployee)
                                .data.profile?.alt ?? 'Author Image'
                            }
                          />
                        ))}
                    </div>
                    {(doc.author.document as Queries.PrismicEmployee).data.name}
                  </div>
                ) : (
                  <div className={styles.author}>
                    <img src={MikeAvatar} alt="Author Image" />
                    Mike
                  </div>
                )
              ) : null}
              <time dateTime={datetime} className={styles.date}>
                {date}
              </time>
              <Share
                link={`https://gamurs.group/blog/${data.prismicArticle.uid}`}
                title={doc.title?.text ?? ''}
              />
            </div>
            <div className={styles.blurb}>
              <PrismicRichText field={doc.blurb ? doc.blurb.richText : []} />
            </div>
            {checkImageIsSVG(doc.featured_image?.url ?? '') ? (
              <div className={styles.imageWrapper}>
                <img
                  src={doc.featured_image ? doc.featured_image.url ?? '' : ''}
                  alt={
                    doc.featured_image
                      ? doc.featured_image.alt ?? 'Article Featured Image'
                      : 'Article Featured Image'
                  }
                />
              </div>
            ) : (
              <GatsbyImage
                className={styles.imageWrapper}
                image={
                  doc.featured_image
                    ?.gatsbyImageData as unknown as IGatsbyImageData
                }
                alt={doc.featured_image?.alt ?? 'Article Featured Image'}
              />
            )}
          </header>
          <div className={styles.articleBody}>
            <SliceZone slices={doc.body} components={articleComponents} />
          </div>
        </div>
        {doc.show_related_posts && <ArticleList articles={related} limit={6} />}
      </article>
      {doc.highlights?.document && (
        <Highlights
          slice={{
            primary: {
              highlight: {
                document: doc.highlights.document as Queries.PrismicHighlights,
                id: '',
                isBroken: false,
                lang: 'en',
                link_type: 'Document',
                localFile: null,
                raw: null,
                size: null,
                slug: '',
                tags: null,
                target: '',
                type: '',
                uid: '',
                url: '',
              },
              // @ts-ignore
              highlightTitle: {
                text: '',
                html: '',
                raw: '',
                richText: [],
              },
            },
            items: [],
            id: '',
            slice_label: null,
            slice_type: 'highlights_slice',
          }}
          index={0}
          context={{ bgStart: 'darker', otherSlices: [] }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query BlogPost($slug: String, $cat: [String], $tag: [String]) {
    prismicArticle(uid: { eq: $slug }) {
      _previewable
      id
      uid
      first_publication_date
      data {
        ...ArticleDataSEOSocialMeta
        title {
          text
        }
        author {
          document {
            ... on PrismicEmployee {
              id
              data {
                profile {
                  gatsbyImageData
                  url
                  alt
                }
                name
              }
            }
          }
        }
        publish_date(formatString: "DD MMMM YYYY")
        tags {
          tag {
            document {
              ... on PrismicTag {
                id
                data {
                  name
                  slug
                }
              }
            }
          }
        }
        show_related_posts
        featured_image {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          url
          alt
        }
        categories {
          category {
            document {
              ... on PrismicCategory {
                id
                data {
                  slug
                  name
                }
              }
            }
          }
        }
        blurb {
          richText
          text
        }
        highlights {
          document {
            ... on PrismicHighlights {
              id
              data {
                title
                cards_group {
                  card {
                    document {
                      ... on PrismicHighlightCard {
                        id
                        data {
                          title
                          image {
                            gatsbyImageData(placeholder: BLURRED)
                            url
                            alt
                          }
                          description {
                            richText
                          }
                          cta_text
                          cta_link {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body {
          ...ArticleDataBodyTextParagraph
          ...ArticleDataBodyQuote
          ...ArticleDataBodyImage
          ...ArticleDataBodyEmbed
        }
      }
    }
    articleCategoryTag: allPrismicArticle(
      sort: { fields: first_publication_date, order: DESC }
      limit: 3
      filter: {
        uid: { ne: $slug }
        data: {
          categories: { elemMatch: { category: { slug: { in: $cat } } } }
          tags: { elemMatch: { tag: { slug: { in: $tag } } } }
        }
      }
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
          featured_image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            url
            alt
          }
        }
      }
    }
    articleCategoryOnly: allPrismicArticle(
      sort: { fields: first_publication_date, order: DESC }
      limit: 3
      filter: {
        uid: { ne: $slug }
        data: {
          tags: { elemMatch: { tag: { slug: { nin: $tag } } } }
          categories: { elemMatch: { category: { slug: { in: $cat } } } }
        }
      }
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
          featured_image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            url
            alt
          }
        }
      }
    }
    articleTagOnly: allPrismicArticle(
      sort: { fields: first_publication_date, order: DESC }
      filter: {
        uid: { ne: $slug }
        data: {
          tags: { elemMatch: { tag: { slug: { in: $tag } } } }
          categories: { elemMatch: { category: { slug: { nin: $cat } } } }
        }
      }
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
          featured_image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            url
            alt
          }
        }
      }
    }
    articleRecent: allPrismicArticle(
      sort: { fields: first_publication_date, order: DESC }
      limit: 3
      filter: {
        uid: { ne: $slug }
        data: {
          tags: { elemMatch: { tag: { slug: { nin: $tag } } } }
          categories: { elemMatch: { category: { slug: { nin: $cat } } } }
        }
      }
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
          featured_image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            url
            alt
          }
        }
      }
    }
  }
`

export default withPrismicPreview(BlogArticle)
