import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import { FilledLinkToWebField } from '@prismicio/types'
import { BrandTile } from '../components/BrandTile'
import * as styles from './BrandOverview.module.scss'
import { IGatsbyImageData } from 'gatsby-plugin-image'

interface BrandOverviewSliceProps {
  slice: Queries.PrismicHomePageDataBodyBrandsOverview
}

type Image = {
  gatsbyImageData: IGatsbyImageData,
  url: string,
  alt: string,
}

export const BrandOverview = ({ slice }: BrandOverviewSliceProps) => {
  return (
    <section className={styles.brandOverview}>
      {slice.items.map((item, index) => {
        if (index < 10) {
          const brand = item.brand?.document as Queries.PrismicBrand
          return (
            <PrismicLink
              field={brand.data.link as FilledLinkToWebField}
              className={styles.tile}
              key={index}
            >
              <BrandTile
                logoUrl={brand.data.logo?.url ?? ''}
                logoAlt={brand.data.logo?.alt ?? ''}
                logoGatsbyImageData={brand.data.logo?.gatsbyImageData as unknown as IGatsbyImageData}
                tileBackground={brand.data.tile_background as unknown as Image}
                tileBackgroundColor={brand.data.tile_background_color ?? ''}
                tileTextComponent={
                  brand.data.tile_text?.richText.length > 0 ? (
                    <PrismicRichText field={brand.data.tile_text?.richText} />
                  ) : undefined
                }
              />
            </PrismicLink>
          )
        }
      })}
    </section>
  )
}

export const query = graphql`
  fragment HomePageDataBodyBrandsOverview on PrismicHomePageDataBodyBrandsOverview {
    slice_label
    slice_type
    items {
      brand {
        document {
          ... on PrismicBrand {
            id
            data {
              link {
                url
                id
                uid
                link_type
              }
              logo {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              tile_background {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              tile_text {
                richText
              }
              tile_background_color
            }
          }
        }
      }
    }
  }
  fragment HomePageDataBodyBrandsOverview on PrismicHomePageDataBodyBrandsOverview {
    slice_label
    slice_type
    items {
      brand {
        document {
          ... on PrismicBrand {
            id
            data {
              link {
                url
                id
                uid
                link_type
              }
              logo {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              tile_background {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              tile_text {
                richText
              }
              tile_background_color
            }
          }
        }
      }
    }
  }
  fragment GenericPageDataBodyBrandsOverview on PrismicGenericPageDataBodyBrandsOverview {
    slice_label
    slice_type
    items {
      brand {
        document {
          ... on PrismicBrand {
            id
            data {
              link {
                url
                id
                uid
                link_type
              }
              logo {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              tile_background {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              tile_text {
                richText
              }
              tile_background_color
            }
          }
        }
      }
    }
  }
`
