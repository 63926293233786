// extracted by mini-css-extract-plugin
export var blurb = "contact-module--blurb--s-+G8";
export var contact = "contact-module--contact--HoUKq";
export var contactFormWrapper = "contact-module--contactFormWrapper--5eEy+";
export var contactText = "contact-module--contactText--k58t6";
export var content = "contact-module--content--bt8jV";
export var contentContainer = "contact-module--content-container--aya4D";
export var emailtext = "contact-module--emailtext--pwsl6";
export var fourtynineB = "contact-module--fourtynine-b--4UU7C";
export var inputLabel = "contact-module--input-label--Fhozm";
export var pageTitle = "contact-module--pageTitle--wS-Xm";
export var seventysixB = "contact-module--seventysix-b--7pK21";
export var sixteenB = "contact-module--sixteen-b--tH+Hi";
export var sixteenR = "contact-module--sixteen-r--Vkmld";
export var smallNone = "contact-module--smallNone--H5+HB";
export var thirteenMK = "contact-module--thirteen-m-k--rtKRh";
export var thirtynineB = "contact-module--thirtynine-b--F3Hsw";
export var thirtyoneB = "contact-module--thirtyone-b--Y5CV1";
export var titletext = "contact-module--titletext--JiHnR";
export var twentyB = "contact-module--twenty-b--lWhIP";
export var twentyR = "contact-module--twenty-r--f8xQC";
export var twentyfiveB = "contact-module--twentyfive-b--6r2kB";