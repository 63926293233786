import * as React from 'react'

import { graphql } from 'gatsby'
import { PrismicLink } from '@prismicio/react'
import { FilledLinkToWebField } from '@prismicio/types'

import * as buttonStyles from '../components/Button.module.scss'
import { StatsHighlighter } from '../components/StatsHighlighter'
import { getSliceBackgroundColor, SlicePageDataContext } from './index'

import * as styles from './StatsHighlighter.module.scss'

interface StatsHighlighterSliceProps {
  context: SlicePageDataContext
  index: number
  slice:
    | Queries.PrismicAdvertisingPageDataBodyStatshighlighter
    | Queries.PrismicHomePageDataBodyStatshighlighter
}

export const StatsHighlighterSlice = ({
  context,
  index,
  slice,
}: StatsHighlighterSliceProps) => {
  const sliceBackgroundColor = getSliceBackgroundColor(
    context.bgStart,
    context.otherSlices,
    index
  )
  let stats = slice.items.map((e) => ({
    label: e.label ?? '',
    value: e.value ?? ''
  }))
  return (
    <StatsHighlighter
      blurb={slice.primary.blurb?.text ?? ''}
      className={styles.statsHighlighter}
      ctaComponent={
        <PrismicLink
          field={slice.primary.cta_link as FilledLinkToWebField}
          className={buttonStyles.button}
        >
          {slice.primary.cta_text}
        </PrismicLink>
      }
      hasDarkerBackgroundColor={sliceBackgroundColor === 'darker'}
      layout={
        slice.primary.variant === '2 columns: text left, 2 col stats right'
          ? 'alternate'
          : 'default'
      }
      stats={stats}
      title={slice.primary.title?.text ?? ''}
      statsSource={slice.primary.source ?? ''}
    />
  )
}

export const query = graphql`
  fragment AdvertisingPageDataBodyStatsHighlighter on PrismicAdvertisingPageDataBodyStatshighlighter {
    slice_label
    slice_type
    id
    items {
      label
      value
    }
    primary {
      variant
      blurb {
        text
      }
      cta_link {
        id
        url
        target
        link_type
      }
      cta_text
      source
      title {
        text
      }
    }
  }
  fragment HomePageDataBodyStatsHighlighter on PrismicHomePageDataBodyStatshighlighter {
    slice_label
    slice_type
    items {
      label
      value
    }
    primary {
      variant
      blurb {
        text
      }
      cta_link {
        id
        url
        target
        link_type
      }
      cta_text
      source
      title {
        text
      }
    }
  }
  fragment GenericPageDataBodyStatsHighlighter on PrismicGenericPageDataBodyStatshighlighter {
    slice_label
    slice_type
    items {
      label
      value
    }
    primary {
      variant
      blurb {
        text
      }
      cta_link {
        id
        url
        target
        link_type
      }
      cta_text
      source
      title {
        text
      }
    }
  }
`
