import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { PrismicLink, PrismicRichText } from '@prismicio/react'

import { button } from '../components/Button.module.scss'
import { checkImageIsSVG } from '../utils/urlUtils'
import { StatsHighlighter } from '../components/StatsHighlighter'
import { SlicePageDataContext, getSliceBackgroundColor } from './index'

import * as styles from './BrandCollection.module.scss'

interface BrandCollectionSliceProps {
  slice: Queries.PrismicBrandsPageDataBodyBrandCollection
  index: number
  context: SlicePageDataContext & { pageLabel: string }
}

export const BrandCollection = ({
  slice,
  index,
  context,
}: BrandCollectionSliceProps) => {
  const sliceBackgroundColor = getSliceBackgroundColor(
    context.bgStart,
    context.otherSlices,
    index
  )
  const brands =
    (
      slice.primary.brands?.document as Queries.PrismicBrandsCategory
    )?.data.brands
      ?.map((brand) => {
        const brandData = (brand?.brand?.document as Queries.PrismicBrand)?.data
        return {
          blurb: brandData.blurb?.richText,
          cta_text: brandData.cta_text,
          featured_image: brandData.featured_image,
          link: brandData.link?.url,
          logo: brandData.logo_horizontal,
        }
      })
      .filter((brand) => !!brand) ?? []
  const title =
    slice.primary.title?.text ??
    (slice.primary.brands?.document as Queries.PrismicBrandsCategory)?.data
      .category_name
  return (
    <section
      key={slice.id}
      className={`${styles.brandCategory} ${
        sliceBackgroundColor === 'darker'
          ? 'backgroundDarker'
          : 'backgroundDark'
      }`}
    >
      <div className={styles.container}>
        <StatsHighlighter
          title={title ?? ''}
          blurb={slice.primary.blurb?.text ?? ''}
          layout="alternate"
          hasDarkerBackgroundColor={sliceBackgroundColor === 'darker'}
          stats={[
            {
              label: slice.primary.stat_1_label ?? '',
              value: slice.primary.stat_1 ?? '',
            },
            {
              label: slice.primary.stat_2_label ?? '',
              value: slice.primary.stat_2 ?? '',
            },
          ]}
        />
        <div className={styles.brandWrapper}>
          {brands.map((brand, index: number) => {
            const isImageRight: Boolean = ((slice.primary
              .image_side_toggle as unknown as number) ^
              index % 2) as unknown as Boolean
            return (
              <section
                key={`brand=${index}`}
                className={`${styles.brand} ${
                  isImageRight ? styles.imageRight : styles.imageLeft
                }`}
              >
                {brand.featured_image &&
                  (checkImageIsSVG(brand.featured_image.url as string) ? (
                    <div className={styles.image}>
                      <img
                        src={brand.featured_image.url}
                        alt={brand.featured_image.alt ?? ''}
                      />
                    </div>
                  ) : (
                    <GatsbyImage
                      className={styles.image}
                      image={brand.featured_image.gatsbyImageData as unknown as IGatsbyImageData}
                      alt={brand.featured_image.alt ?? ''}
                    />
                  ))}
                <div>
                  <div className={styles.brandInfo}>
                    <div>
                      {brand.logo &&
                        (checkImageIsSVG(brand.logo.url ?? '') ? (
                          <img
                            src={brand.logo?.url ?? undefined}
                            alt={brand.logo.alt ?? ''}
                          />
                        ) : (
                          <GatsbyImage
                            image={brand.logo.gatsbyImageData as unknown as IGatsbyImageData}
                            alt={brand.logo.alt ?? ''}
                          />
                        ))}
                    </div>
                    <PrismicRichText field={brand.blurb} />
                    <div>
                      <PrismicLink href={brand.link} className={button}>
                        {brand.cta_text ? brand.cta_text : 'Visit Website'}
                      </PrismicLink>
                    </div>
                  </div>
                </div>
              </section>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment BrandsPageDataBodyBrandCollection on PrismicBrandsPageDataBodyBrandCollection {
    slice_label
    slice_type
    primary {
      blurb {
        text
      }
      title {
        text
      }
      source
      image_side_toggle
      stat_1
      stat_1_label
      stat_2
      stat_2_label
      brands {
        document {
          ... on PrismicBrandsCategory {
            id
            data {
              brands {
                brand {
                  document {
                    ... on PrismicBrand {
                      id
                      data {
                        blurb {
                          richText
                        }
                        featured_image {
                          gatsbyImageData(placeholder: BLURRED)
                          url
                          alt
                        }
                        link {
                          url
                        }
                        logo_horizontal {
                          gatsbyImageData(placeholder: BLURRED)
                          url
                          alt
                        }
                        name
                        cta_text
                      }
                    }
                  }
                }
              }
              category_name
            }
          }
        }
      }
    }
  }
  fragment GenericPageDataBodyBrandCollection on PrismicGenericPageDataBodyBrandCollection {
    slice_label
    slice_type
    primary {
      blurb {
        text
      }
      title {
        text
      }
      source
      image_side_toggle
      stat_1
      stat_1_label
      stat_2
      stat_2_label
      brands {
        document {
          ... on PrismicBrandsCategory {
            id
            data {
              brands {
                brand {
                  document {
                    ... on PrismicBrand {
                      id
                      data {
                        blurb {
                          richText
                        }
                        featured_image {
                          gatsbyImageData(placeholder: BLURRED)
                          url
                          alt
                        }
                        link {
                          url
                        }
                        logo_horizontal {
                          gatsbyImageData(placeholder: BLURRED)
                          url
                          alt
                        }
                        name
                        cta_text
                      }
                    }
                  }
                }
              }
              category_name
            }
          }
        }
      }
    }
  }
`
