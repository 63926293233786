import * as React from 'react'

import { useStaticQuery } from 'gatsby'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import classNames from 'classnames'

import { Layout } from '../components/Layout'
import { SEOSocialMeta } from '../components/SEOSocialMeta'

import {
  brandsComponents,
  getSliceBackgroundColor,
  getSliceContextFromPageData,
} from '../slices'

import * as styles from './brands.module.scss'

const BrandsPage = () => {
  const data = useStaticQuery<Queries.BrandsPageQuery>(graphql`
    query BrandsPage {
      prismicBrandsPage {
        _previewable
        data {
          ...BrandsPageDataSEOSocialMeta
          slice_background_starting_color
          page_label
          body {
            ... on PrismicSliceType {
              id
              slice_type
              slice_label
            }
            ...BrandsPageDataBodyBrandCollection
            ...BrandsPageDataBodyHighlightsSlice
            ...BrandsPageDataBodyArticleList
          }
        }
      }
    }
  `)
  if (!data || !data.prismicBrandsPage) return null
  const doc = data.prismicBrandsPage.data
  return (
    <Layout>
      <SEOSocialMeta
        facebook={{
          description: doc.facebook_description,
          image: doc.facebook_image?.url,
          title: doc.facebook_title,
        }}
        seo={{
          description: doc.seo_description,
          title: doc.seo_title,
        }}
        twitter={{
          description: doc.twitter_description,
          image: doc.twitter_image?.url,
          title: doc.twitter_title,
        }}
      />
      <h2
        className={classNames(
          styles.pageLabel,
          getSliceBackgroundColor(
            doc.slice_background_starting_color ? 'darker' : 'dark',
            [],
            0
          )
            ? 'backgroundDarker'
            : 'backgroundDark'
        )}
      >
        {doc.page_label ?? 'Brands'}
      </h2>
      <SliceZone
        slices={doc.body}
        components={brandsComponents}
        context={{
          ...getSliceContextFromPageData(doc),
          pageLabel: doc.page_label,
        }}
      />
    </Layout>
  )
}
export default withPrismicPreview(BrandsPage)
