import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import * as styles from './ArticleSlices.module.scss'

interface ArticleQuoteProps {
  slice: Queries.PrismicArticleDataBodyQuote
}

export const ArticleQuote = ({ slice }: ArticleQuoteProps) => {
  return (
    <figure className={`${styles.slice} ${styles.quote}`}>
      <blockquote>
        <PrismicRichText field={slice.primary.quote?.richText} />
      </blockquote>
      {slice.primary.author?.richText.text && (
        <figcaption>
          <div className={styles.author}>
            - <PrismicRichText field={slice.primary.author?.richText} />
          </div>
        </figcaption>
      )}
    </figure>
  )
}

export const query = graphql`
  fragment ArticleDataBodyQuote on PrismicArticleDataBodyQuote {
    id
    slice_type
    slice_label
    primary {
      author {
        richText
        text
      }
      quote {
        richText
      }
    }
  }
  fragment GenericPageDataBodyQuote on PrismicGenericPageDataBodyQuote {
    id
    slice_type
    slice_label
    primary {
      author {
        richText
        text
      }
      quote {
        richText
      }
    }
  }
`
