import * as React from 'react'

import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

import { getSliceBackgroundColor, SlicePageDataContext } from './index'

import * as styles from './AdsProductServices.module.scss'
import * as button from '../components/Button.module.scss'
import { checkImageIsSVG } from '../utils/urlUtils'

interface AdsProductServicesSliceProps {
  slice: Queries.PrismicAdvertisingPageDataBodyAdsProductsAndServices
  index: number
  context: SlicePageDataContext
}

export const AdsProductServices = ({
  slice,
  index,
  context,
}: AdsProductServicesSliceProps) => {
  const sliceBackgroundColor = getSliceBackgroundColor(
    context.bgStart,
    context.otherSlices,
    index
  )
  return (
    <section
      key={slice.id}
      className={`${styles.info} ${
        sliceBackgroundColor === 'darker'
          ? 'backgroundDarker'
          : 'backgroundDark'
      }`}
    >
      <div
        className={`${styles.wrapper} ${
          slice.primary.text_side ? styles.textRight : styles.textLeft
        }`}
      >
        <div className={styles.text}>
          <h1>{slice.primary.title?.text}</h1>
          <div className={styles.blurb}>
            <PrismicRichText field={slice.primary.blurb?.richText} />
          </div>
          <div>
            <a className={button.button} href={slice.primary.cta_link?.url ?? ''}>
              {slice.primary.cta_text}
            </a>
          </div>
        </div>
        <figure className={styles.image}>
          {slice.primary.image && (
            checkImageIsSVG(slice.primary?.image?.url ?? '') ? (
              <img
                src={slice.primary.image?.url ?? undefined}
                alt={slice.primary.image?.alt ?? ''}
              />
            ) : (
              <GatsbyImage
                image={slice.primary.image?.gatsbyImageData as unknown as IGatsbyImageData}
                objectFit="contain"
                alt={slice.primary.image?.alt ?? ''}
              />
            )
          )}
        </figure>
      </div>
      {slice.primary.show_highlight_section && (
        <div className={styles.wrapper}>
          <section className={styles.highlight}>
            <div className={styles.highlightText}>
              <h1>{slice.primary.highlight_title?.text}</h1>
              <div className={styles.blurb}>
                <PrismicRichText
                  field={slice.primary.hightlight_blurb?.richText}
                />
              </div>
            </div>
            <div className={styles.button}>
              <a
                className={button.button}
                href={slice.primary.highlight_cta_link?.url ?? undefined}
              >
                {slice.primary.highlight_cta_text}
              </a>
            </div>
          </section>
        </div>
      )}
    </section>
  )
}

export const query = graphql`
  fragment AdvertisingPageDataBodyAdsProductsAndServices on PrismicAdvertisingPageDataBodyAdsProductsAndServices {
    id
    slice_type
    slice_label
    primary {
      blurb {
        richText
      }
      cta_text
      cta_link {
        url
      }
      highlight_cta_link {
        url
      }
      highlight_cta_text
      highlight_title {
        text
      }
      hightlight_blurb {
        richText
      }
      image {
        alt
        url
        gatsbyImageData(placeholder: BLURRED)
      }
      show_highlight_section
      text_side
      title {
        text
      }
    }
  }
  fragment GenericPageDataBodyAdsProductsAndServices on PrismicGenericPageDataBodyAdsProductsAndServices {
    id
    slice_type
    slice_label
    primary {
      blurb {
        richText
      }
      cta_text
      cta_link {
        url
      }
      highlight_cta_link {
        url
      }
      highlight_cta_text
      highlight_title {
        text
      }
      hightlight_blurb {
        richText
      }
      image {
        alt
        url
        gatsbyImageData(placeholder: BLURRED)
      }
      show_highlight_section
      text_side
      title {
        text
      }
    }
  }
`
