import * as React from 'react'
import * as styles from './HighlightCard.module.scss'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { checkImageIsSVG } from '../utils/urlUtils'

type HighlightCardProps = {
  link: React.ReactNode
  description: any
  imageAlt: string
  imageUrl: string
  imageGatsbyImageData: IGatsbyImageData
  title: string
}

export const HighlightCard = ({
  link,
  description,
  imageAlt,
  imageUrl,
  imageGatsbyImageData,
  title,
}: HighlightCardProps) => {
  return (
    <div
      className={`${styles.panel} ${
        link !== undefined ? styles.large : styles.small
      }`}
    >
      <div className={styles.image}>
        {checkImageIsSVG(imageUrl) ? (
          <img src={imageUrl} alt={imageAlt ?? ''} />
        ) : (
          <GatsbyImage image={imageGatsbyImageData} alt={imageAlt ?? ''} />
        )}
      </div>
      <span className={styles.title}>{title}</span>
      <div className={styles.blurb}>{description}</div>
      {link}
    </div>
  )
}
