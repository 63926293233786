// extracted by mini-css-extract-plugin
export var blurb = "BrandTile-module--blurb--nF2jK";
export var blurbtextblack = "BrandTile-module--blurbtextblack--ntN2J";
export var blurbtextwhite = "BrandTile-module--blurbtextwhite--Ay5VR";
export var fourtynineB = "BrandTile-module--fourtynine-b--dSQ-P";
export var inputLabel = "BrandTile-module--input-label--W6bAV";
export var linktext = "BrandTile-module--linktext---kZKD";
export var noBlurb = "BrandTile-module--noBlurb--PUnYr";
export var seventysixB = "BrandTile-module--seventysix-b--hYBj0";
export var sixteenB = "BrandTile-module--sixteen-b--CITwf";
export var sixteenR = "BrandTile-module--sixteen-r--X-4DG";
export var thirteenMK = "BrandTile-module--thirteen-m-k--HFvPm";
export var thirtynineB = "BrandTile-module--thirtynine-b--LYtIl";
export var thirtyoneB = "BrandTile-module--thirtyone-b--EM53z";
export var tile = "BrandTile-module--tile--z53XC";
export var tileBackground = "BrandTile-module--tileBackground--dJC91";
export var tileLogoLiquid = "BrandTile-module--tileLogoLiquid--+pZPD";
export var tileLogoSolid = "BrandTile-module--tileLogoSolid--u2TrL";
export var twentyB = "BrandTile-module--twenty-b--GFcEA";
export var twentyR = "BrandTile-module--twenty-r--7OcNu";
export var twentyfiveB = "BrandTile-module--twentyfive-b--P+qCp";