import * as React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

import * as styles from './EmployeeProfile.module.scss'

import profile from '../images/mike/mike-profile.svg'
import { checkImageIsSVG } from '../utils/urlUtils'

type EmployeeProfileProps = {
  name: string
  photoUrl: string
  photoAlt: string
  photoGatsbyImageData: any
  links?: Array<{ website: string; url: string }> | null
  title: string
}

export const EmployeeProfile = ({
  name,
  photoUrl,
  photoAlt,
  photoGatsbyImageData,
  links,
  title,
}: EmployeeProfileProps) => {
  return (
    <div className={styles.card}>
      {photoGatsbyImageData ? (
        checkImageIsSVG(photoUrl) ? (
          <img
            src={photoUrl}
            alt={photoAlt ?? `Image of ${name}`}
            className={styles.profile}
          />
        ) : (
          <GatsbyImage
            alt={photoAlt ?? `Image of ${name}`}
            image={photoGatsbyImageData}
            imgClassName={styles.profile}
            objectFit="cover"
          />
        )
      ) : (
        <img src={profile} alt="Default Image" className={styles.profile} />
      )}
      <div className={styles.infobox}>
        <div className={styles.name}>{name}</div>
        <div className={styles.title}>{title}</div>
        <div className={styles.links}>
          <div className={styles.socials}>
            {links?.map(
              (social, index: number) =>
                social.website &&
                social.url && (
                  <a
                    className={`icon-${social.website.toLowerCase()} filter-ash`}
                    href={social.url}
                    key={index}
                  >
                    {social.website}
                  </a>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
