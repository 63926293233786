// extracted by mini-css-extract-plugin
export var fourtynineB = "SkipToContent-module--fourtynine-b--xA8lB";
export var inputLabel = "SkipToContent-module--input-label--DCuEL";
export var seventysixB = "SkipToContent-module--seventysix-b--ofOOX";
export var sixteenB = "SkipToContent-module--sixteen-b--0F3SX";
export var sixteenR = "SkipToContent-module--sixteen-r--l4Moh";
export var skipToContent = "SkipToContent-module--skipToContent--PQl1Y";
export var thirteenMK = "SkipToContent-module--thirteen-m-k--HSu3y";
export var thirtynineB = "SkipToContent-module--thirtynine-b--VGSQH";
export var thirtyoneB = "SkipToContent-module--thirtyone-b--96AmD";
export var twentyB = "SkipToContent-module--twenty-b--Jy4wG";
export var twentyR = "SkipToContent-module--twenty-r--Cic4D";
export var twentyfiveB = "SkipToContent-module--twentyfive-b--W5kRH";