import * as React from 'react'
import type { PageProps } from 'gatsby'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PageHero } from '../components/PageHero'
import { Layout } from '../components/Layout'
import { SliceZone } from '@prismicio/react'
import { advertisingComponents, getSliceContextFromPageData } from '../slices'
import { SEOSocialMeta } from '../components/SEOSocialMeta'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import * as clientsGridStyles from '../components/ClientsGrid.module.scss'
import * as styles from './advertising.module.scss'
import { checkImageIsSVG } from '../utils/urlUtils'

const AdvertisingPage = ({ data }: PageProps<Queries.AdvertisingPageQuery>) => {
  if (!data || !data.prismicAdvertisingPage) return null
  const doc = data.prismicAdvertisingPage.data
  return (
    <Layout>
      <SEOSocialMeta
        facebook={{
          description: doc.facebook_description,
          image: doc.facebook_image?.url,
          title: doc.facebook_title,
        }}
        seo={{
          description: doc.seo_description,
          title: doc.seo_title,
        }}
        twitter={{
          description: doc.twitter_description,
          image: doc.twitter_image?.url,
          title: doc.twitter_title,
        }}
      />
      <PageHero
        isDarker={doc.slice_background_starting_color}
        label={doc.page_label ?? 'Advertising'}
        title={doc.clients_title ?? 'Advertise with us to reach new audiences'}
        centerComponent={
          <div
            className={classNames(
              styles.clients,
              clientsGridStyles.clientsGrid
            )}
          >
            {doc.previous_clients !== null
              ? doc.previous_clients.map((entry, index) =>
                  checkImageIsSVG(entry?.client_logo?.url ?? '') ? (
                    <img
                      src={entry?.client_logo?.url ?? ''}
                      alt={entry?.client_logo?.alt ?? ''}
                      className={classNames(`${clientsGridStyles.img}`, {
                        [`${clientsGridStyles.hiddenMobile}`]: index >= 10,
                      })}
                    />
                  ) : (
                    <GatsbyImage
                      alt={entry?.client_logo?.alt ?? ''}
                      image={
                        entry?.client_logo
                          ?.gatsbyImageData as unknown as IGatsbyImageData
                      }
                      imgClassName={clientsGridStyles.img}
                      className={classNames({
                        [`${clientsGridStyles.hiddenMobile}`]: index >= 10,
                      })}
                      loading="eager"
                      objectFit="scale-down"
                    />
                  )
                )
              : null}
          </div>
        }
      />
      <SliceZone
        slices={doc.body}
        components={advertisingComponents}
        context={{
          ...getSliceContextFromPageData(doc),
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  query AdvertisingPage {
    prismicAdvertisingPage {
      _previewable
      data {
        ...AdvertisingPageDataSEOSocialMeta
        page_label
        clients_title
        previous_clients {
          client_logo {
            gatsbyImageData(placeholder: BLURRED)
            url
            alt
          }
        }
        slice_background_starting_color
        page_label
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...AdvertisingPageDataBodyAdsProductsAndServices
          ...AdvertisingPageDataBodyStatsHighlighter
          ...AdvertisingPageDataBodyArticleList
        }
      }
    }
  }
`

export default withPrismicPreview(AdvertisingPage)
