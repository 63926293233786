import * as React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

import { getSliceBackgroundColor, SlicePageDataContext } from './index'
import { HighlightCard } from '../components/HighlightCard'

import * as styles from './Highlights.module.scss'
import { button } from '../components/Button.module.scss'
import { IGatsbyImageData } from 'gatsby-plugin-image'

type Image = {
  gatsbyImageData: IGatsbyImageData
  alt: string
  url: string
}

type URL = {
  url: string
}

type IHighlightCard = {
  card: {
    document: {
      data: Card
      id: string
    }
  }
}

type Card = {
  cta_link: URL
  description: Queries.PrismicStructuredTextType
  image: Image
  title: string
  cta_text: string
}

type SliceData = {
  title: string
  cards_group?: IHighlightCard[]
}

interface HighlightsSliceProps {
  slice:
    | Queries.PrismicAboutPageDataBodyHighlightsSlice
    | Queries.PrismicBrandsPageDataBodyHighlightsSlice
    | Queries.PrismicHomePageDataBodyHighlightsSlice
  index: number
  context: SlicePageDataContext
}

export const Highlights = ({ slice, index, context }: HighlightsSliceProps) => {
  const sliceBackgroundColor = getSliceBackgroundColor(
    context.bgStart,
    context.otherSlices,
    index
  )
  const limit: number = 3

  let slicedata: SliceData = { title: '', cards_group: undefined }
  if (slice.primary && slice.primary.highlight?.document !== null) {
    slicedata = slice.primary.highlight?.document.data as SliceData
  } else {
    slicedata = {
      title: '',
      cards_group: slice.items as unknown as IHighlightCard[],
    }
  }
  // @ts-ignore Error as a result of use of alias. Todo: rename field in prismic
  if (slice.primary && slice.primary.highlightTitle.text !== '') {
    // @ts-ignore 
    slicedata.title = slice.primary.highlightTitle.text
  }

  return (
    <section
      key={slice.id}
      className={classNames(
        styles.ctaSection,
        sliceBackgroundColor === 'darker'
          ? 'backgroundDarker'
          : 'backgroundDark'
      )}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div>
            <h1>{slicedata.title}</h1>
          </div>
          <div className={styles.tiles}>
            {slicedata.cards_group &&
              slicedata.cards_group.map(
                (item, index) =>
                  index < limit && (
                    <HighlightCard
                      key={index}
                      link={
                        item.card.document.data.cta_link ? (
                          <PrismicLink
                            href={item.card.document.data.cta_link.url}
                            className={button}
                          >
                            {item.card.document.data.cta_text
                              ? item.card.document.data.cta_text
                              : 'Learn more'}
                          </PrismicLink>
                        ) : (
                          ''
                        )
                      }
                      description={
                        <PrismicRichText
                          field={item.card.document.data.description.richText}
                        />
                      }
                      imageAlt={item.card.document.data.image.alt}
                      imageUrl={item.card.document.data.image.url}
                      imageGatsbyImageData={
                        item.card.document.data.image.gatsbyImageData
                      }
                      title={item.card.document.data.title}
                    />
                  )
              )}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment AboutPageDataBodyHighlightsSlice on PrismicAboutPageDataBodyHighlightsSlice {
    id
    slice_type
    slice_label
    primary {
      highlightTitle: title {
        text
      }
      highlight {
        document {
          ... on PrismicHighlights {
            id
            data {
              cards_group {
                card {
                  id
                  document {
                    ... on PrismicHighlightCard {
                      id
                      data {
                        cta_link {
                          url
                        }
                        cta_text
                        description {
                          richText
                        }
                        image {
                          gatsbyImageData(placeholder: BLURRED)
                          url
                          alt
                        }
                        title
                      }
                    }
                  }
                }
              }
              title
            }
          }
        }
      }
    }
    items {
      card {
        document {
          ... on PrismicHighlightCard {
            id
            data {
              title
              image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              description {
                richText
              }
              cta_text
              cta_link {
                url
              }
            }
          }
        }
      }
    }
  }
  fragment BrandsPageDataBodyHighlightsSlice on PrismicBrandsPageDataBodyHighlightsSlice {
    id
    slice_type
    slice_label
    primary {
      highlightTitle: title {
        text
      }
      highlight {
        document {
          ... on PrismicHighlights {
            id
            data {
              cards_group {
                card {
                  id
                  document {
                    ... on PrismicHighlightCard {
                      id
                      data {
                        cta_link {
                          url
                        }
                        cta_text
                        description {
                          richText
                        }
                        image {
                          gatsbyImageData(placeholder: BLURRED)
                          url
                          alt
                        }
                        title
                      }
                    }
                  }
                }
              }
              title
            }
          }
        }
      }
    }
    items {
      card {
        document {
          ... on PrismicHighlightCard {
            id
            data {
              title
              image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              description {
                richText
              }
              cta_text
              cta_link {
                url
              }
            }
          }
        }
      }
    }
  }
  fragment HomePageDataBodyHighlightsSlice on PrismicHomePageDataBodyHighlightsSlice {
    id
    slice_type
    slice_label
    primary {
      highlightTitle: title {
        text
      }
      highlight {
        document {
          ... on PrismicHighlights {
            id
            data {
              cards_group {
                card {
                  id
                  document {
                    ... on PrismicHighlightCard {
                      id
                      data {
                        cta_link {
                          url
                        }
                        cta_text
                        description {
                          richText
                        }
                        image {
                          gatsbyImageData(placeholder: BLURRED)
                          url
                          alt
                        }
                        title
                      }
                    }
                  }
                }
              }
              title
            }
          }
        }
      }
    }
    items {
      card {
        document {
          ... on PrismicHighlightCard {
            id
            data {
              title
              image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              description {
                richText
              }
              cta_text
              cta_link {
                url
              }
            }
          }
        }
      }
    }
  }
  fragment GenericPageDataBodyHighlightsSlice on PrismicGenericPageDataBodyHighlightsSlice {
    id
    slice_type
    slice_label
    primary {
      highlightTitle: title {
        text
      }
      highlight {
        document {
          ... on PrismicHighlights {
            id
            data {
              cards_group {
                card {
                  id
                  document {
                    ... on PrismicHighlightCard {
                      id
                      data {
                        cta_link {
                          url
                        }
                        cta_text
                        description {
                          richText
                        }
                        image {
                          gatsbyImageData(placeholder: BLURRED)
                          url
                          alt
                        }
                        title
                      }
                    }
                  }
                }
              }
              title
            }
          }
        }
      }
    }
    items {
      card {
        document {
          ... on PrismicHighlightCard {
            id
            data {
              title
              image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              description {
                richText
              }
              cta_text
              cta_link {
                url
              }
            }
          }
        }
      }
    }
  }
`
