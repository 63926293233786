import * as React from 'react'
import { Helmet } from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'

import * as styles from './ContactForm.module.scss'
import classNames from 'classnames'

type recaptchaJSONResponse = {
  success: boolean
}

type ValidFormState = 
  'start'
    | 'entry'
    | 'captcha'
    | 'submitting'
    | 'failed captcha'
    | 'submitted'
    | 'error'

export const ContactForm = () => {
  const recaptchaRef: React.RefObject<ReCAPTCHA> = React.useRef(null)
  const formRef: React.RefObject<HTMLFormElement> = React.useRef(null)
  const [topic, setTopic] = React.useState<
      'Advertising' | 'Careers' | 'Other' | null
    >(null)
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [feedback, setFeedback] = React.useState('')
  const [formState, setFormState] = React.useState<ValidFormState>('start');

  function resizeMessageBox(event: React.FormEvent<HTMLDivElement>) {
    let input: HTMLTextAreaElement = event.target as HTMLTextAreaElement
    input.style.height = 'auto'
    input.style.height = input.scrollHeight + 'px'
  }

  function submitform(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    changeFormState('captcha')
  }

  async function executeInvisibleRecaptcha() {
    let token = ''
    try {
      if (recaptchaRef.current) {
        let tempToken: string | null = await recaptchaRef.current.executeAsync();
        token = tempToken ? tempToken : ''
      }
    } catch (e) {
      console.log(e)
    }
    let recaptchaVerification: Response = await fetch('/api/verifycaptcha', {
      method: 'POST',
      body: JSON.stringify({
        token: token,
      }),
    })
    let recaptchaVerificationJson: recaptchaJSONResponse = await recaptchaVerification.json()
    if (recaptchaVerificationJson.success) {
      changeFormState('submitting')
    } else {
      changeFormState('failed captcha')
    }
  }

  async function sendMessage() {
    let formdata = {
      topic: topic ? topic : 'Other',
      email: email,
      message: message,
    }
    try {
      await fetch('/api/sendemail', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(formdata),
      })
      changeFormState('submitted')
    } catch (err) {
      changeFormState('error')
    }
  }

  function resetCaptcha() {
    try {
      if (recaptchaRef.current && recaptchaRef.current.reset) {
        recaptchaRef.current.reset();
      }
    } catch (e) {
      console.log(e)
    }
  }

  // state switcher verification: only allow state swaps along the correct flow
  function changeFormState(state: ValidFormState ) {
    switch(state) {
      case 'start':
        if (formState === 'submitted') { // form resets only after submitting (successful)
          setFormState('start')
        }
        break;
      case 'entry':
        if (formState === 'start' // form in entry mode after starting
            || formState === 'captcha' // form reverts to entry if captcha cancelled
            || formState === 'error' // form reverts to entry if error in sending
            || formState === 'failed captcha') { // form reverts to entry if error in captcha
          setFormState('entry')
        }
        break;
      case 'captcha':
        if (formState === 'entry') { // form submits captcha after entry finished
          setFormState('captcha')
        }
        break;
      case 'submitting':
        if (formState === 'captcha') { // form is submitting after captcha verified success
          setFormState('submitting')
        }
        break;
      case 'failed captcha':
        if (formState === 'captcha') { // form failed captcha after captcha
          setFormState('failed captcha')
        }
        break;
      case 'submitted':
        if (formState === 'submitting') { // form success after submitting
          setFormState('submitted')
        }
        break;
      case 'error':
        if (formState === 'submitting') { // form error after submitting attempt
          setFormState('error')
        }
        break;
    }
  }

  // Execute tasks based on state of form
  React.useEffect(() => {
    switch(formState) {
      case 'captcha':
        setFeedback('Submitting...')
        executeInvisibleRecaptcha()
        break;
      case 'submitting':
        setFeedback('Submitting...')
        sendMessage()
        resetCaptcha()
        break;
      case 'failed captcha':
        resetCaptcha()
        setFeedback('Error: Recaptcha failed. Please refresh and try again')
        setTimeout(() => changeFormState('entry'), 2000);
        break;
      case 'entry':
        setFeedback('')
        break;
      case 'start':
        setFeedback('')
        setTopic(null)
        setEmail('')
        setMessage('')
        changeFormState('entry')
        break;
      case 'error':
        setFeedback('Error: Try again later');
        setTimeout(() => changeFormState('entry'), 2000);
        break;
      case 'submitted':
        setFeedback('MESSAGE SENT');
        setTimeout(() => changeFormState('start'), 2000);
        break;
    }
  }, [formState])

  React.useEffect(() => {
    function captchaOnClose() {
      if (!recaptchaRef.current?.getValue()) {
        changeFormState('entry')
      }
    }
    function initCaptchaobserver() {
      let captchaiframes = Array.from(
        document.getElementsByTagName('iframe')
      ).filter((e) => e.src.includes('/recaptcha/api2/bframe'))
      if (captchaiframes.length === 0) {
        return []
      }
      let recaptchaWindow: HTMLElement = captchaiframes[0].parentNode!
        .parentNode as HTMLElement
      let observer: MutationObserver | null = null;
      if (recaptchaWindow) {
        observer = new MutationObserver(
          (e) => recaptchaWindow.style.opacity === '0' && captchaOnClose()
        )
        observer.observe(recaptchaWindow, {
          attributes: true,
          attributeFilter: ['style'],
        })
      }
      return [
        () => {
          if (observer) {
            observer.disconnect()
          }
        }
      ]
    }
    let close = initCaptchaobserver()
    return () => {
      close.forEach(e => e())
    }
  }, [formState])

  return (
    <div className={styles.contactForm}>
      <Helmet>
        <script
          src="https://www.recaptcha.net/recaptcha/api.js"
          async
          defer
        ></script>
      </Helmet>
      <form
        ref={formRef}
        className={styles.formcontainer}
        onSubmit={submitform}
      >
        <h3>What do you want to talk about?</h3>
        <div className={styles.topicSelector}>
          <button
            onClick={(e) => {
              e.preventDefault()
              setTopic('Advertising')
              changeFormState('entry')
            }}
            className={classNames(
              styles.topicButton,
              topic === 'Advertising' && styles.selected
            )}
          >
            Advertising
          </button>
          <button
            onClick={(e) => {
              e.preventDefault()
              setTopic('Careers')
              changeFormState('entry')
            }}
            className={classNames(
              styles.topicButton,
              topic === 'Careers' && styles.selected
            )}
          >
            Careers
          </button>
          <button
            onClick={(e) => {
              e.preventDefault()
              setTopic('Other')
              changeFormState('entry')
            }}
            className={classNames(
              styles.topicButton,
              topic === 'Other' && styles.selected
            )}
          >
            Other
          </button>
        </div>
        <div className={`${styles.inputfield} ${styles.formentrycontent}`}>
          <input
            id="form-email-input"
            name="email"
            required
            disabled={formState === 'start'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label className={styles.floatinglabel} htmlFor="form-email-input">
            Email *
          </label>
          <div className={styles.assistive} />
        </div>
        <div
          className={`${styles.inputfield} ${styles.formentrycontent}`}
          onInput={resizeMessageBox}
        >
          <textarea
            id="form-message-input"
            name="message"
            required
            disabled={formState === 'start'}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <label className={styles.floatinglabel} htmlFor="form-message-input">
            Message *
          </label>
          <div className={styles.assistive} />
        </div>

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY as string}
          badge="bottomright"
          theme="dark"
          size="invisible"
          style={{ visibility: 'hidden' }}
          className={styles.formentrycontent}
          onChange={() => {}}
        />
        <div className={`${styles.captchatext} ${styles.formentrycontent}`}>
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
          apply.
        </div>
        <button
          className={styles.submitbutton}
          disabled={formState !== 'entry' || !/.+@.+/.test(email) || message === ''}
        >
          Send Message
        </button>
        <div
          className={`${styles.feedback} ${
            feedback !== '' ? styles.showFeedback : styles.hideFeedback
          }`}
        >
          <div>{feedback}</div>
        </div>
      </form>
    </div>
  )
}
