import * as React from 'react'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText, SliceZone } from '@prismicio/react'
import { IGatsbyImageData } from 'gatsby-plugin-image'

import { Layout } from '../components/Layout'
import { HomepageHero } from '../components/HomepageHero'
import { SEOSocialMeta } from '../components/SEOSocialMeta'

import { getSliceContextFromPageData, homepageComponents } from '../slices'

type SlideType =  {
  titleComponent?: JSX.Element
  image?: {
    alt: string | null | undefined
    gatsbyImageData: IGatsbyImageData
    url: string | null | undefined
  } | null
  imageSmall?: {
    alt: string | null | undefined
    gatsbyImageData: IGatsbyImageData
    url: string | null | undefined
  } | null
}

const HomePage = ({ data }: PageProps<Queries.HomePageQuery>) => {
  if (!data || !data.prismicHomePage) return null
  const doc = data.prismicHomePage.data
  const slides = !doc.hero_slides
    ? []
    : doc.hero_slides.map((slide) => ({
        titleComponent: <PrismicRichText field={slide?.hero_title?.richText} />,
        image: {
          alt: slide?.hero_slide_image?.alt,
          gatsbyImageData: slide?.hero_slide_image?.gatsbyImageData,
          url: slide?.hero_slide_image?.url,
        },
        imageSmall: slide?.hero_slide_image?.thumbnails?.small,
      }))
  const slides_duration = doc.slides_duration
  const slide_fade = doc.slide_fade

  return (
    <Layout>
      <SEOSocialMeta
        facebook={{
          description: doc.facebook_description,
          image: doc.facebook_image?.url,
          title: doc.facebook_title,
        }}
        seo={{
          description: doc.seo_description,
          title: doc.seo_title,
        }}
        twitter={{
          description: doc.twitter_description,
          image: doc.twitter_image?.url,
          title: doc.twitter_title,
        }}
      />
      <HomepageHero
        slides={slides as unknown as SlideType[]}
        slidesDuration={slides_duration}
        isDarker={doc.slice_background_starting_color ?? false}
        slideFade={slide_fade}
      />
      <SliceZone
        slices={doc.body}
        components={homepageComponents}
        context={{
          ...getSliceContextFromPageData(doc),
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  query HomePage {
    prismicHomePage {
      _previewable
      data {
        ...HomePageDataSEOSocialMeta
        slides_duration
        slide_fade
        hero_slides {
          hero_title {
            richText
          }
          hero_slide_image {
            gatsbyImageData(placeholder: NONE)
            url
            thumbnails {
              small {
                gatsbyImageData(placeholder: NONE)
                url
                alt
              }
            }
            alt
          }
        }
        slice_background_starting_color
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...HomePageDataBodyArticleList
          ...HomePageDataBodyBrandsOverview
          ...HomePageDataBodyHighlightsSlice
          ...HomePageDataBodyStatsHighlighter
        }
      }
    }
  }
`

export default withPrismicPreview(HomePage)
