// extracted by mini-css-extract-plugin
export var brand = "BrandCollection-module--brand--i+3lM";
export var brandCategory = "BrandCollection-module--brandCategory--pG5yR";
export var brandInfo = "BrandCollection-module--brandInfo--ky03S";
export var brandWrapper = "BrandCollection-module--brandWrapper--kbj1r";
export var container = "BrandCollection-module--container--0vAdB";
export var contentContainer = "BrandCollection-module--content-container--7Pfj0";
export var extraLargeOnly = "BrandCollection-module--extraLargeOnly--Db1Hz";
export var fourtynineB = "BrandCollection-module--fourtynine-b--f9M0f";
export var image = "BrandCollection-module--image--z707o";
export var imageLeft = "BrandCollection-module--imageLeft--tZXji";
export var imageRight = "BrandCollection-module--imageRight--8vaKQ";
export var inputLabel = "BrandCollection-module--input-label--jejRF";
export var seventysixB = "BrandCollection-module--seventysix-b--JRtBM";
export var sixteenB = "BrandCollection-module--sixteen-b--ajfYH";
export var sixteenR = "BrandCollection-module--sixteen-r--9+yye";
export var thirteenMK = "BrandCollection-module--thirteen-m-k--pwHJ4";
export var thirtynineB = "BrandCollection-module--thirtynine-b--ZPLT5";
export var thirtyoneB = "BrandCollection-module--thirtyone-b--X43M1";
export var twentyB = "BrandCollection-module--twenty-b--no+QN";
export var twentyR = "BrandCollection-module--twenty-r--zm2bi";
export var twentyfiveB = "BrandCollection-module--twentyfive-b--SW4mB";