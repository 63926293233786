// extracted by mini-css-extract-plugin
export var bacMain = "BenefitsAndCulture-module--bacMain--fz3XU";
export var benefits = "BenefitsAndCulture-module--benefits--cEn-j";
export var blurb = "BenefitsAndCulture-module--blurb--zriS9";
export var container = "BenefitsAndCulture-module--container--wbd16";
export var contentContainer = "BenefitsAndCulture-module--content-container--jNM4j";
export var feature = "BenefitsAndCulture-module--feature--OmYqe";
export var fourtynineB = "BenefitsAndCulture-module--fourtynine-b--RXl5j";
export var info = "BenefitsAndCulture-module--info--eRD2u";
export var inputLabel = "BenefitsAndCulture-module--input-label--5-dqn";
export var seventysixB = "BenefitsAndCulture-module--seventysix-b--2NuFf";
export var sixteenB = "BenefitsAndCulture-module--sixteen-b--sjkBi";
export var sixteenR = "BenefitsAndCulture-module--sixteen-r--4ydGY";
export var thirteenMK = "BenefitsAndCulture-module--thirteen-m-k--lqzwD";
export var thirtynineB = "BenefitsAndCulture-module--thirtynine-b--8mg8l";
export var thirtyoneB = "BenefitsAndCulture-module--thirtyone-b--gPBZL";
export var twentyB = "BenefitsAndCulture-module--twenty-b--2yVP8";
export var twentyR = "BenefitsAndCulture-module--twenty-r--8mJkz";
export var twentyfiveB = "BenefitsAndCulture-module--twentyfive-b--4AWL8";