import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

import { HighlightCard } from '../components/HighlightCard'
import { getSliceBackgroundColor, SlicePageDataContext } from './index'

import * as styles from './Highlights.module.scss'
import { IGatsbyImageData } from 'gatsby-plugin-image'

interface ValueHighlightsSliceProps {
  slice: Queries.PrismicCareersPageDataBodyValuesHighlight
  index: number
  context: SlicePageDataContext
}

export const ValueHighlights = ({
  slice,
  index,
  context,
}: ValueHighlightsSliceProps) => {
  const sliceBackgroundColor = getSliceBackgroundColor(
    context.bgStart,
    context.otherSlices,
    index
  )
  const limit: number = 4
  const slicedata = {
    title: 'Our Values',
    cards_group: slice.items,
  }

  return (
    <section
      key={slice.id}
      className={`${styles.ctaSection} ${
        sliceBackgroundColor === 'darker'
          ? 'backgroundDarker'
          : 'backgroundDark'
      }`}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div>
            <h1>{slicedata.title}</h1>
          </div>
          <div className={`${styles.tiles} ${styles.wrap}`}>
            {slicedata.cards_group &&
              slicedata.cards_group.map((item, index) => {
                if (index < limit) {
                  const companyValue = (
                    item.company_values?.document as Queries.PrismicCompanyValue
                  ).data
                  return (
                    <HighlightCard
                      key={index}
                      link={undefined}
                      description={
                        <PrismicRichText
                          field={companyValue.description?.richText}
                        />
                      }
                      imageAlt={companyValue.image?.alt ?? ''}
                      imageUrl={companyValue.image?.url ?? ''}
                      imageGatsbyImageData={companyValue.image?.gatsbyImageData as unknown as IGatsbyImageData}
                      title={companyValue.value ?? ''}
                    />
                  )
                }
              })}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CareersPageDataBodyValuesHighlight on PrismicCareersPageDataBodyValuesHighlight {
    id
    slice_label
    slice_type
    items {
      company_values {
        document {
          ... on PrismicCompanyValue {
            id
            data {
              description {
                richText
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              value
            }
          }
        }
      }
    }
  }
  fragment GenericPageDataBodyValuesHighlight on PrismicGenericPageDataBodyValuesHighlight {
    id
    slice_label
    slice_type
    items {
      company_values {
        document {
          ... on PrismicCompanyValue {
            id
            data {
              description {
                richText
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              value
            }
          }
        }
      }
    }
  }
`
