// extracted by mini-css-extract-plugin
export var contentContainer = "about-module--content-container--5L5EH";
export var fourtynineB = "about-module--fourtynine-b--dW2Ot";
export var inputLabel = "about-module--input-label--r5Lzq";
export var largeOnly = "about-module--largeOnly--8HZYn";
export var seventysixB = "about-module--seventysix-b--wjpGR";
export var sixteenB = "about-module--sixteen-b---VRHT";
export var sixteenR = "about-module--sixteen-r--AazKu";
export var statSource = "about-module--statSource--GWa6a";
export var stats = "about-module--stats--Ckt5w";
export var thirteenMK = "about-module--thirteen-m-k--7LqTj";
export var thirtynineB = "about-module--thirtynine-b--m1SZk";
export var thirtyoneB = "about-module--thirtyone-b--u2GYO";
export var twentyB = "about-module--twenty-b--69SWW";
export var twentyR = "about-module--twenty-r--KKMam";
export var twentyfiveB = "about-module--twentyfive-b--JbjqM";