import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

import { getSliceBackgroundColor, SlicePageDataContext } from './index'

import * as styles from './BenefitsAndCulture.module.scss'
import { checkImageIsSVG } from '../utils/urlUtils'
interface BenefitsAndCultureSliceProps {
  slice: Queries.PrismicCareersPageDataBodyBenefitsAndCulture
  index: number
  context: SlicePageDataContext
}

export const BenefitsAndCulture = ({
  slice,
  index,
  context,
}: BenefitsAndCultureSliceProps) => {
  const sliceBackgroundColor = getSliceBackgroundColor(
    context.bgStart,
    context.otherSlices,
    index
  )
  React.useEffect(() => {
    document.addEventListener('readystatechange', (e) => {
      if (e.target !== null && (e.target as Document).readyState === 'complete') {
        Array.from(document.querySelectorAll('blockquote')).map((e) => {
          let script = document.createElement('script')
          script.src = (e.nextElementSibling as HTMLIFrameElement)?.src
          document.head.appendChild(script)
        })
      }
    })
  })
  let embed: JSX.Element | undefined = undefined
  if (slice.primary.embed__custom_?.text !== '') {
    embed = (
      <div
        dangerouslySetInnerHTML={{ __html: slice.primary.embed__custom_?.text ?? '' }}
      />
    )
  } else if (slice.primary.embed) {
    embed = (
      <div dangerouslySetInnerHTML={{ __html: slice.primary.embed.html ?? '' }} />
    )
  } else if (slice.primary.image !== null) {
    embed = checkImageIsSVG(slice.primary.image.url ?? '') ? (
      <img src={slice.primary.image.url ?? ''} alt={slice.primary.image.alt ?? ''} />
    ) : (
      <GatsbyImage
        image={slice.primary.image.gatsbyImageData as unknown as IGatsbyImageData}
        alt={slice.primary.image.alt ?? ''}
      />
    )
  } else {
    <div>DEFAULT</div>
  }
  return (
    <section
      className={`${styles.bacMain} ${
        sliceBackgroundColor === 'darker'
          ? 'backgroundDarker'
          : 'backgroundDark'
      }`}
    >
      <div className={styles.container}>
        <div className={styles.info}>
          <h1>{slice.primary.bac_title?.text}</h1>
          <div className={styles.blurb}>
            <PrismicRichText field={slice.primary.bac_blurb?.richText} />
          </div>
          <div className={styles.benefits}>
            {slice.primary.benefit_1 && <div>{slice.primary.benefit_1}</div>}
            {slice.primary.benefit_2 && <div>{slice.primary.benefit_2}</div>}
            {slice.primary.benefit_3 && <div>{slice.primary.benefit_3}</div>}
          </div>
        </div>
        <div className={styles.feature}>{embed !== undefined && embed}</div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CareersPageDataBodyBenefitsAndCulture on PrismicCareersPageDataBodyBenefitsAndCulture {
    id
    slice_label
    slice_type
    primary {
      image {
        gatsbyImageData(placeholder: BLURRED)
        url
        alt
      }
      embed__custom_ {
        text
      }
      embed {
        html
      }
      benefit_3
      benefit_2
      benefit_1
      bac_title {
        text
      }
      bac_blurb {
        richText
      }
    }
  }
  fragment GenericPageDataBodyBenefitsAndCulture on PrismicGenericPageDataBodyBenefitsAndCulture {
    id
    slice_label
    slice_type
    primary {
      image {
        gatsbyImageData(placeholder: BLURRED)
        url
        alt
      }
      embed__custom_ {
        text
      }
      embed {
        html
      }
      benefit_3
      benefit_2
      benefit_1
      bac_title {
        text
      }
      bac_blurb {
        richText
      }
    }
  }
`
