import * as React from 'react'
import * as styles from './BrandTile.module.scss'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { checkImageIsSVG } from '../utils/urlUtils'

type BrandTileProps = {
  logoUrl: string
  logoAlt: string
  logoGatsbyImageData: IGatsbyImageData
  tileBackground: { gatsbyImageData: IGatsbyImageData; alt: string; url: string }
  tileBackgroundColor: string
  tileTextComponent: React.ReactNode
}

export const BrandTile = ({
  logoUrl,
  logoAlt,
  logoGatsbyImageData,
  tileBackground,
  tileBackgroundColor,
  tileTextComponent,
}: BrandTileProps) => {
  function hexToRGB(hex: string) {
    let hexvalues = hex.substring(1).match(/.{2}/g)
    return hexvalues ? [
      parseInt(hexvalues[0], 16),
      parseInt(hexvalues[1], 16),
      parseInt(hexvalues[2], 16),
    ] : null
  }

  function luminance(hex: string) {
    let rgb: number[] | null = hexToRGB(hex)
    if (rgb) {
      rgb = rgb.map((e: number) => e / 255)
      rgb = rgb.map((e: number) =>
        e > 0.03928 ? Math.pow((e + 0.055) / 1.055, 2.4) : e / 12.92
      )
      return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]
    } else {
      return 0;
    }
  }

  function contrastRatio(hex1: string, hex2: string) {
    const lum1 = luminance(hex1)
    const lum2 = luminance(hex2)
    return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05)
  }

  function blackorwhite(hex: string) {
    const white = contrastRatio(hex, '#ffffff') //contrast with white
    const black = contrastRatio(hex, '#1b1b1b') //contrast with void
    return black > white ? 'black' : 'white'
  }

  let textColor = 'white'
  if (tileBackgroundColor) {
    textColor = blackorwhite(tileBackgroundColor)
  }
  return (
    <div className={styles.tile}>
      <div
        className={`${styles.blurb} ${
          textColor === 'black' ? styles.blurbtextblack : styles.blurbtextwhite
        }`}
        style={{
          background: tileBackgroundColor ? tileBackgroundColor : '#1b1b1b',
        }}
      >
        {tileTextComponent}
      </div>
      {checkImageIsSVG(logoUrl) ? (
        <img
          alt={logoAlt ?? ''}
          className={`${
            tileTextComponent ? styles.tileLogoLiquid : styles.tileLogoSolid
          }`}
          src={logoUrl}
        />
      ) : (
        <GatsbyImage
          alt={logoAlt ?? ''}
          className={`${
            tileTextComponent ? styles.tileLogoLiquid : styles.tileLogoSolid
          }`}
          image={logoGatsbyImageData}
          objectFit="scale-down"
        />
      )}

      {tileBackground.gatsbyImageData !== null ? (
        checkImageIsSVG(tileBackground.url) ? (
          <img
            src={tileBackground.url}
            alt={tileBackground.alt ?? ''}
            className={styles.tileBackground}
          />
        ) : (
          <GatsbyImage
            image={tileBackground.gatsbyImageData}
            className={styles.tileBackground}
            alt={tileBackground.alt ?? ''}
          />
        )
      ) : (
        <div
          style={{ background: tileBackgroundColor }}
          className={styles.tileBackground}
        />
      )}
    </div>
  )
}
