import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

import * as styles from './SiteFooter.module.scss'

import GAMURSLogo from '../images/logos/logo-dark.svg'
import MikeImage from '../images/mike/mike-contact-dark.svg'

type SocalProps = {
  social_network: string
  social_account_link: { url: string }
}
type LocationProps = {
  location_name: string
  location_address: { html: string }
  location: any
}
type LegalProps = {
  page: any
}

export const SiteFooter = () => {
  const queryData = useStaticQuery(graphql`
    query FooterData {
      prismicFooter {
        data {
          contact_us_paragraph {
            html
            text
          }
          office_locations {
            location {
              document {
                ... on PrismicLocation {
                  id
                  data {
                    location_name
                    address {
                      richText
                    }
                  }
                }
              }
            }
          }
          social_links {
            social_network
            social_account_link {
              url
            }
          }
          legal_links {
            page {
              document {
                ... on PrismicLegalPage {
                  data {
                    slug
                    title {
                      text
                    }
                  }
                  id
                }
              }
            }
          }
        }
      }
    }
  `)
  const footerData = queryData.prismicFooter.data
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.socials}>
          <Link className={styles.logo} to="/">
            <img src={GAMURSLogo} alt="GAMURS logo" />
          </Link>
          <div className={styles.socialLinks}>
            {footerData.social_links.map((e: SocalProps) => (
              <a
                className={`icon-${e.social_network.toLowerCase()}`}
                href={e.social_account_link.url}
                key={e.social_network}
              >
                <span>{e.social_network}</span>
              </a>
            ))}
          </div>
        </div>
        <div className={styles.data}>
          <div className={styles.info}>
            <div className={styles.navigation}>
              <h2 className={styles.columnHeader}>Company</h2>
              <nav>
                <Link to="/about">About</Link>
                <Link to="/brands">Brands</Link>
                <Link to="/advertising">Advertising</Link>
                <Link to="/careers">Careers</Link>
                <Link to="/blog">Blog</Link>
              </nav>
            </div>

            <div className={styles.location}>
              <h2 className={styles.columnHeader}>Locations</h2>
              <div className={styles.locations}>
                {footerData.office_locations.map((e: LocationProps) => (
                  <div key={e.location.document.data.location_name}>
                    <span className={styles.locationName}>
                      {e.location.document.data.location_name}
                    </span>
                    <div className={styles.locationAddress}>
                      <PrismicRichText
                        field={e.location.document.data.address.richText}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.contact}>
            <h2 className={styles.columnHeader}>Have a question?</h2>
            <div
              className={styles.contactBlurb}
              dangerouslySetInnerHTML={{
                __html: footerData.contact_us_paragraph.html,
              }}
            ></div>
            <div>
              <Link className={styles.button} to="/contact">
                Contact us
              </Link>
              <img
                src={MikeImage}
                className={styles.mike}
                alt="Mike"
                aria-hidden
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.legal}>
        <div className={styles.legalcontent}>
          <div>Copyright @GAMURS Group 2022</div>
          <div className={styles.legalLinks}>
            {footerData.legal_links.map((e: LegalProps) => (
              <a
                href={`/${e.page.document.data.slug}`}
                key={e.page.document.data.slug}
              >
                {e.page.document.data.title.text}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}
