import * as React from 'react'
import * as styles from './Stat.module.scss'

type StatProps = {
  large?: boolean
  value: string
  label: string
}

export const Stat = (props: StatProps) => (
  <div
    className={`${styles.singleStatContainer} ${
      props.large ? styles.paddingDefault : styles.paddingMedium
    }`}
  >
    <figure>
      <p
        className={`${styles.stat} ${
          props.large ? styles.statLarge : styles.statMedium
        }`}
      >
        {props.value}
      </p>
      <figcaption>
        <p className={styles.statLabel}>{props.label}</p>
      </figcaption>
    </figure>
  </div>
)
