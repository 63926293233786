// extracted by mini-css-extract-plugin
export var fourtynineB = "Stat-module--fourtynine-b--qhK45";
export var inputLabel = "Stat-module--input-label--4QGOd";
export var paddingDefault = "Stat-module--paddingDefault--IRXlx";
export var paddingMedium = "Stat-module--paddingMedium--HDb29";
export var seventysixB = "Stat-module--seventysix-b--h4SKl";
export var singleStatContainer = "Stat-module--singleStatContainer--j4XXd";
export var sixteenB = "Stat-module--sixteen-b--jzF9Z";
export var sixteenR = "Stat-module--sixteen-r--mWprV";
export var stat = "Stat-module--stat--awbzH";
export var statLabel = "Stat-module--statLabel--cax66";
export var statLarge = "Stat-module--statLarge--4xFwZ";
export var statMedium = "Stat-module--statMedium--uVkNl";
export var thirteenMK = "Stat-module--thirteen-m-k--tGOuj";
export var thirtynineB = "Stat-module--thirtynine-b--JAf1V";
export var thirtyoneB = "Stat-module--thirtyone-b--lYBwP";
export var twentyB = "Stat-module--twenty-b--+fGdi";
export var twentyR = "Stat-module--twenty-r--64j2t";
export var twentyfiveB = "Stat-module--twentyfive-b---PciC";