import { IGatsbyImageData } from 'gatsby-plugin-image'
import * as React from 'react'
import { ArticleTile } from './ArticleTile'
import * as styles from './ArticleList.module.scss'
import classNames from 'classnames'

type ArticleListProps = {
  articles: Array<{
    slug: string
    featuredImage: {
      url: string
      alt: string
      gatsbyImageData: IGatsbyImageData
    } | null
    title: string
  }>
  limit: number
  twoColumnsMax?: boolean
}

export const ArticleList = ({
  articles,
  limit,
  twoColumnsMax = false,
}: ArticleListProps) => {
  return (
    <div
      className={classNames(
        styles.articleList,
        twoColumnsMax && styles.twoColumnsArticleList
      )}
    >
      {articles.map(
        (post, index: number) =>
          (limit === undefined || index < limit) && (
            <ArticleTile
              key={post.slug}
              slug={post.slug}
              image={post.featuredImage}
              title={post.title}
            />
          )
      )}
    </div>
  )
}
