// extracted by mini-css-extract-plugin
export var container = "TeamSlice-module--container--ENJKX";
export var fourtynineB = "TeamSlice-module--fourtynine-b--NxiNH";
export var inputLabel = "TeamSlice-module--input-label--gTGj7";
export var party = "TeamSlice-module--party--hlp7D";
export var seventysixB = "TeamSlice-module--seventysix-b--b2izi";
export var sixteenB = "TeamSlice-module--sixteen-b--6BZ33";
export var sixteenR = "TeamSlice-module--sixteen-r--SToy1";
export var teamSlice = "TeamSlice-module--teamSlice--8pBJA";
export var thirteenMK = "TeamSlice-module--thirteen-m-k--y7ndC";
export var thirtynineB = "TeamSlice-module--thirtynine-b--MohMz";
export var thirtyoneB = "TeamSlice-module--thirtyone-b--yozI1";
export var title = "TeamSlice-module--title--BY5v4";
export var twentyB = "TeamSlice-module--twenty-b--F+ihk";
export var twentyR = "TeamSlice-module--twenty-r--cJgZG";
export var twentyfiveB = "TeamSlice-module--twentyfive-b--gvfoO";