import * as React from 'react'
import { PrismicRichText } from '@prismicio/react'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Stat } from '../components/Stat'
import { Layout } from '../components/Layout'
import { PageHero } from '../components/PageHero'
import { aboutComponents, getSliceContextFromPageData } from '../slices'

import * as styles from './about.module.scss'
import { SEOSocialMeta } from '../components/SEOSocialMeta'

const AboutPage = ({ data }: PageProps<Queries.AboutPageQuery>) => {
  if (!data || !data.prismicAboutPage) return null
  const doc = data.prismicAboutPage.data
  return (
    <Layout>
      <SEOSocialMeta
        facebook={{
          description: doc.facebook_description,
          image: doc.facebook_image?.url,
          title: doc.facebook_title,
        }}
        seo={{
          description: doc.seo_description,
          title: doc.seo_title,
        }}
        twitter={{
          description: doc.twitter_description,
          image: doc.twitter_image?.url,
          title: doc.twitter_title,
        }}
      />
      <PageHero
        isDarker={doc.slice_background_starting_color ?? false}
        label={doc.page_label ?? 'About'}
        titleComponent={<PrismicRichText field={doc.hero_title?.richText} />}
        centerComponent={
          doc.hero_stats != null ? (
            <div className={styles.stats}>
              {doc.hero_stats?.map((entry, index) => (
                <Stat
                  key={index}
                  value={entry?.hero_stat_value?.text ?? ''}
                  label={entry?.hero_stat_label?.text ?? ''}
                />
              ))}
              {doc.source && (
                <>
                  <div className={styles.largeOnly}></div>
                  <div className={styles.largeOnly}></div>
                  <div className={styles.statSource}>
                    Source: {doc.source}
                  </div>
                </>
              )}
            </div>
          ) : undefined
        }
      />
      <SliceZone
        slices={doc.body}
        components={aboutComponents}
        context={{
          ...getSliceContextFromPageData(doc),
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  query AboutPage {
    prismicAboutPage {
      _previewable
      data {
        ...AboutPageDataSEOSocialMeta
        source
        page_label
        hero_stats {
          hero_stat_label {
            text
          }
          hero_stat_value {
            text
          }
        }
        hero_title {
          html
          richText
          text
        }
        slice_background_starting_color
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...AboutPageDataBodyTimeline
          ...AboutPageDataBodyHighlightsSlice
          ...AboutPageDataBodyParty
          ...AboutPageDataBodyArticleList
        }
      }
    }
  }
`

export default withPrismicPreview(AboutPage)
