// extracted by mini-css-extract-plugin
export var blurb = "AdsProductServices-module--blurb--+opFE";
export var button = "AdsProductServices-module--button--5zuy0";
export var fourtynineB = "AdsProductServices-module--fourtynine-b--5SlNz";
export var highlight = "AdsProductServices-module--highlight---P-br";
export var highlightText = "AdsProductServices-module--highlightText--rCMnQ";
export var image = "AdsProductServices-module--image--bnE1i";
export var info = "AdsProductServices-module--info--D7ym+";
export var inputLabel = "AdsProductServices-module--input-label--xgncJ";
export var seventysixB = "AdsProductServices-module--seventysix-b--6137Y";
export var sixteenB = "AdsProductServices-module--sixteen-b--+LbPU";
export var sixteenR = "AdsProductServices-module--sixteen-r--gUTUS";
export var text = "AdsProductServices-module--text--nJLIi";
export var textLeft = "AdsProductServices-module--textLeft--8PYFi";
export var textRight = "AdsProductServices-module--textRight--FC5tz";
export var thirteenMK = "AdsProductServices-module--thirteen-m-k--JkSXL";
export var thirtynineB = "AdsProductServices-module--thirtynine-b--yaj4P";
export var thirtyoneB = "AdsProductServices-module--thirtyone-b--jLfDk";
export var twentyB = "AdsProductServices-module--twenty-b--oj3AP";
export var twentyR = "AdsProductServices-module--twenty-r--ZHFLB";
export var twentyfiveB = "AdsProductServices-module--twentyfive-b--+kfGI";
export var wrapper = "AdsProductServices-module--wrapper--p9aBh";