import * as React from 'react'

import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import classNames from 'classnames'

import * as styles from './ArticleSlices.module.scss'
import { checkImageIsSVG } from '../utils/urlUtils'

interface ArticleImageProps {
  slice: Queries.PrismicArticleDataBodyImage
}

export const ArticleImage = ({ slice }: ArticleImageProps) => {
  return (
    <figure className={classNames(styles.slice, styles.image)}>
      {checkImageIsSVG(slice.primary?.image?.url ?? '') ? (
        <img
          src={slice.primary.image?.url ?? undefined}
          alt={slice.primary.image?.alt ?? ''}
        />
      ) : (
        <GatsbyImage
          image={slice.primary.image?.gatsbyImageData as unknown as IGatsbyImageData}
          alt={slice.primary.image?.alt ?? ''}
        />
      )}
    </figure>
  )
}

export const query = graphql`
  fragment ArticleDataBodyImage on PrismicArticleDataBodyImage {
    id
    slice_type
    slice_label
    primary {
      image {
        gatsbyImageData(placeholder: BLURRED)
        url
        alt
      }
    }
  }
  fragment GenericPageDataBodyImage on PrismicGenericPageDataBodyImage {
    id
    slice_type
    slice_label
    primary {
      image {
        gatsbyImageData(placeholder: BLURRED)
        url
        alt
      }
    }
  }
`
