import * as React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'

import { getSliceBackgroundColor, SlicePageDataContext } from './index'

import * as styles from './Timeline.module.scss'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { checkImageIsSVG } from '../utils/urlUtils'

interface TimelineSliceProps {
  slice: Queries.PrismicAboutPageDataBodyTimeline
  index: number
  context: SlicePageDataContext
}

export const Timeline = ({ slice, index, context }: TimelineSliceProps) => {
  const sliceBackgroundColor = getSliceBackgroundColor(
    context.bgStart,
    context.otherSlices,
    index
  )
  return (
    <section
      className={`${
        sliceBackgroundColor === 'darker'
          ? 'backgroundDarker'
          : 'backgroundDark'
      }`}
    >
      {slice.primary.timeline_title ? (
        <h1 className={styles.timelineTitle}>
          {slice.primary.timeline_title.text}
        </h1>
      ) : null}
      <div className={styles.timelineContainerMobile}>
        <div className={styles.timelineLine} />
        {slice.items.map((entry, index) => (
          <div
            className={classNames(
              ((index % 2 ^
                ((sliceBackgroundColor ===
                  'darker') as unknown as number)) as unknown as Boolean)
                ? 'backgroundDarker'
                : 'backgroundDark'
            )}
          >
            <div className={styles.timelineContentMobile}>
              {entry.section_opening_point?.text || index === 0 ? (
                <div
                  className={classNames(
                    styles.openingPoint,
                    index === 0 && styles.first
                  )}
                >
                  <div
                    className={classNames(
                      styles.timelineDot,
                      styles.blackbg,
                      styles.first
                    )}
                  ></div>
                  <div className={classNames(styles.pointContent)}>
                    {entry.section_opening_point?.text}
                  </div>
                </div>
              ) : null}
              <div className={styles.focusPoint}>
                <div
                  className={classNames(styles.timelineDot, styles.noDot)}
                ></div>
                <figure>
                  {checkImageIsSVG(entry.image?.url ?? '') ? (
                    <img
                      src={entry.image?.url ?? ''}
                      alt={entry.image?.alt ?? ''}
                      className={styles.img}
                    />
                  ) : (
                    <GatsbyImage
                      image={entry.image?.gatsbyImageData as unknown as IGatsbyImageData}
                      alt={entry.image?.alt ?? ''}
                      imgClassName={styles.img}
                    />
                  )}
                </figure>
                <div className={classNames(styles.timelineDot)}></div>
                <div className={styles.pointContent}>
                  <div className={classNames(styles.title)}>
                    {entry.section_main_title?.text}
                  </div>
                  <div className={classNames(styles.desc)}>
                    {entry.main_description?.text}
                  </div>
                </div>
              </div>
              {entry.section_closing_point?.text ||
              index === slice.items.length - 1 ? (
                <div className={styles.closingPoint}>
                  <div
                    className={classNames(styles.timelineDot, styles.blackbg)}
                  />
                  <div className={classNames(styles.pointContent)}>
                    {entry.section_closing_point?.text}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.timelineContainer}>
        <div className={styles.timelineLine} />
        {slice.items.map((entry, index) => (
          <div
            className={
              ((index % 2 ^
                ((sliceBackgroundColor ===
                  'darker') as unknown as number)) as unknown as Boolean)
                ? 'backgroundDarker'
                : 'backgroundDark'
            }
          >
            <div className={styles.timelineContent}>
              {entry.section_opening_point?.text || index === 0 ? (
                <div
                  className={classNames(
                    styles.openingPoint,
                    index === 0 && styles.first
                  )}
                >
                  <div
                    className={classNames(
                      styles.right,
                      index == 0 && styles.first
                    )}
                  >
                    {entry.layout == 'Image Left, Text Right'
                      ? entry.section_opening_point?.text
                      : ''}
                  </div>
                  <div
                    className={classNames(
                      styles.timelineDot,
                      styles.first,
                      styles.blackbg
                    )}
                  ></div>
                  <div className={classNames(styles.left)}>
                    {entry.layout == 'Text Left, Image Right'
                      ? entry.section_opening_point?.text
                      : ''}
                  </div>
                </div>
              ) : null}
              <div className={styles.focusPoint}>
                <div key={index} className={styles.right}>
                  {entry.layout == 'Image Left, Text Right' && (
                    <figure>
                      {checkImageIsSVG(entry.image?.url ?? '') ? (
                        <img
                          src={entry.image?.url ?? ''}
                          alt={entry.image?.alt ?? ''}
                          className={styles.img}
                        />
                      ) : (
                        <GatsbyImage
                          image={entry.image?.gatsbyImageData as unknown as IGatsbyImageData}
                          alt={entry.image?.alt ?? ''}
                          imgClassName={styles.img}
                        />
                      )}
                    </figure>
                  )}
                  {entry.layout == 'Text Left, Image Right' && (
                    <>
                      <p className={styles.title}>
                        {entry.section_main_title?.text}
                      </p>
                      <p className={styles.desc}>
                        {entry.main_description?.text}
                      </p>
                    </>
                  )}
                </div>
                <div className={classNames(styles.timelineDot)}></div>
                <div key={index} className={styles.left}>
                  {entry.layout == 'Image Left, Text Right' && (
                    <>
                      <p className={styles.title}>
                        {entry.section_main_title?.text}
                      </p>
                      <p className={styles.desc}>
                        {entry.main_description?.text}
                      </p>
                    </>
                  )}
                  {entry.layout == 'Text Left, Image Right' && (
                    <figure>
                      {checkImageIsSVG(entry.image?.url ?? '') ? (
                        <img
                          src={entry.image?.url ?? ''}
                          alt={entry.image?.alt ?? ''}
                          className={styles.img}
                        />
                      ) : (
                        <GatsbyImage
                          image={entry.image?.gatsbyImageData as unknown as IGatsbyImageData}
                          alt={entry.image?.alt ?? ''}
                          imgClassName={styles.img}
                        />
                      )}
                    </figure>
                  )}
                </div>
              </div>
              {entry.section_closing_point?.text ||
              index === slice.items.length - 1 ? (
                <div className={styles.closingPoint}>
                  <div className={styles.right}>
                    {entry.layout == 'Image Left, Text Right'
                      ? entry.section_closing_point?.text
                      : ''}
                  </div>
                  <div
                    className={classNames(styles.timelineDot, styles.blackbg)}
                  />
                  <div className={styles.left}>
                    {entry.layout == 'Text Left, Image Right'
                      ? entry.section_closing_point?.text
                      : ''}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment AboutPageDataBodyTimeline on PrismicAboutPageDataBodyTimeline {
    slice_type
    slice_label
    items {
      image {
        gatsbyImageData(placeholder: BLURRED)
        url
        alt
      }
      layout
      last_section
      main_description {
        text
      }
      section_closing_point {
        text
      }
      section_main_title {
        text
      }
      section_opening_point {
        text
      }
    }
    primary {
      timeline_title {
        text
      }
    }
  }
  fragment GenericPageDataBodyTimeline on PrismicGenericPageDataBodyTimeline {
    slice_type
    slice_label
    items {
      image {
        gatsbyImageData(placeholder: BLURRED)
        url
        alt
      }
      layout
      last_section
      main_description {
        text
      }
      section_closing_point {
        text
      }
      section_main_title {
        text
      }
      section_opening_point {
        text
      }
    }
    primary {
      timeline_title {
        text
      }
    }
  }
`
