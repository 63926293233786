import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import * as styles from './ArticleSlices.module.scss'

interface ArticleParagraphProps {
  slice: Queries.PrismicArticleDataBodyTextParagraph
}


export const ArticleParagraph = ({ slice }: ArticleParagraphProps) => {
  return (
    <section className={`${styles.slice} ${styles.text}`}>
      <PrismicRichText field={slice.primary.text?.richText}/>
    </section>
  )
}

export const query = graphql`
  fragment ArticleDataBodyTextParagraph on PrismicArticleDataBodyTextParagraph {
    id
    slice_type
    slice_label
    primary {
      text {
        richText
      }
    }
  }
  fragment GenericPageDataBodyTextParagraph on PrismicGenericPageDataBodyTextParagraph {
    id
    slice_type
    slice_label
    primary {
      text {
        richText
      }
    }
  }
`