import * as React from 'react'

import classNames from 'classnames'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { checkImageIsSVG } from '../utils/urlUtils'

import * as styles from './HomepageHero.module.scss'

type HomepageHeroProps = {
  isDarker: boolean
  slides: {
    titleComponent?: JSX.Element
    image?: {
      alt: string | null | undefined
      gatsbyImageData: IGatsbyImageData
      url: string | null | undefined
    } | null
    imageSmall?: {
      alt: string | null | undefined
      gatsbyImageData: IGatsbyImageData
      url: string | null | undefined
    } | null
  }[]
  slidesDuration?: number | null
  slideFade?: boolean | null
}

export const HomepageHero = ({
  slides,
  slidesDuration = 0,
  isDarker = false,
  slideFade = true
}: HomepageHeroProps) => {
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [cursorState, setCursorState] = React.useState(false)
  const [initTyping, setInitTyping] = React.useState(false)
  const [typeHereState, setTypeHereState] = React.useState<
    HTMLElement[] | null
  >(null)
  const [typeTextState, setTypeTextState] = React.useState<string[] | null>(
    null
  )
  const [screenReadersState, setScreenReadersState] = React.useState<
    HTMLElement[] | null
  >(null)
  const [firstLoad, setFirstLoad] = React.useState(true)

  const slideLength = slidesDuration !== null ? slidesDuration * 1000 : 0
  const typingLength = slideLength / 4
  const deleteLength = slideLength / 4
  const fadeoutLength = slideFade !== false ? (slideLength / 4) * 2 : 0
  const fadeoutStart = slideFade !== false ? (slideLength / 4) * 3 : slideLength
  const fadeinLength = slideFade !== false ? slideLength / 4 : 0
  const fadeinStart = 0

  if (slideLength === 0) {
    slides = [slides[0]]
  }

  function fadeIn(element: HTMLElement, duration: number) {
    element.style.animationDuration = `${duration}ms`
    element.classList.add(styles.animateFadeIn)
    setTimeout(() => {
      element.style.animationDuration = `0ms`
      element.style.opacity = '1'
    }, duration-100)
    setTimeout(() => {
      element.classList.remove(styles.animateFadeIn)
    }, duration)
  }

  function fadeOut(element: HTMLElement, duration: number) {
    element.style.animationDuration = `${duration}ms`
    element.classList.add(styles.animateFadeOut)
    setTimeout(() => {
      element.style.animationDuration = `0ms`
      element.style.opacity = '0'
    }, duration-100)
    setTimeout(() => {
      element.classList.remove(styles.animateFadeOut)
    }, duration)
  }

  function preProcessText(text: string, screenReader: HTMLElement): string[] {
    let baseY: number = screenReader.children[0].getBoundingClientRect().y
    let splitText: string[] = text.split(' ')
    let newText: string[] = splitText[0].split('')
    // insert <br/> into text as appropriate as according to screen reader text
    for (let i = 1; i < splitText.length; i++) {
      if (baseY !== screenReader.children[i].getBoundingClientRect().y) {
        newText = newText.concat('<br/>')
      } else {
        newText = newText.concat(' ')
      }
      newText = newText.concat(splitText[i].split(''))
      baseY = screenReader.children[i].getBoundingClientRect().y
    }
    return newText
  }

  function doTyping(node: HTMLElement, text: string[], duration: number) {
    for (let i = 0; i < text.length; i++) {
      setTimeout(
        () => (node.innerHTML = text.slice(0, i + 1).join('')),
        (i * duration) / text.length
      )
    }
  }

  function doDelete(node: HTMLElement, text: string[], duration: number) {
    for (let i = 0; i <= text.length; i++) {
      setTimeout(
        () => (node.innerHTML = text.slice(0, text.length - i).join('')),
        ((i + 1) * duration) / text.length
      )
    }
  }

  let typeHere: HTMLElement[]
  let typeText: string[]
  let screenReaders: HTMLElement[]

  //initialise stuff for typing animation
  React.useEffect(() => {
    if (!initTyping) {
      if (slideLength === 0) {
        ;(
          Array.from(
            document.getElementsByClassName(styles.slide)
          )[0] as HTMLElement
        ).style.opacity = '1'
        setInitTyping(true)
        return
      }
      // location to type the text (the strong)
      typeHere = Array.from(document.getElementsByClassName(styles.title)).map(
        (e) => e.getElementsByTagName('strong')[0]
      )
      // store values of what to type
      typeText = typeHere.map((e) => e.innerHTML)
      // delete content from the strong to manually type later
      typeHere.map((e, i) => {
        if (i !== 0) {
          e.innerText = ''
        }
      })
      // insert line break
      typeHere.map((e) => {
        const parent = e.parentElement!
        let html = parent.innerHTML.split('<')
        html[0] = html[0] + '<br/>'
        parent.innerHTML = html.join('<')
      })
      // refresh typeHeres after dom manipulation
      typeHere = Array.from(document.getElementsByClassName(styles.title)).map(
        (e) => e.getElementsByTagName('strong')[0]
      )
      // do the same for screenReaders
      screenReaders = Array.from(
        document.getElementsByClassName(styles.screenReader)
      ).map((e) => e.getElementsByTagName('strong')[0])
      screenReaders.map((e) => {
        const parent = e.parentElement!
        let html = parent.innerHTML.split('<')
        html[0] = html[0] + '<br/>'
        parent.innerHTML = html.join('<')
      })
      Array.from(document.getElementsByClassName(styles.screenReader)).map(
        (e) => {
          const words = e
            .getElementsByTagName('strong')[0]
            .innerText.split(' ')
            .map((text) => {
              let div = document.createElement('div')
              div.innerText = text
              return div
            })
          e.getElementsByTagName('strong')[0].innerHTML = ''
          words.forEach((word) => {
            e.getElementsByTagName('strong')[0].appendChild(word)
          })
        }
      )
      screenReaders = Array.from(
        document.getElementsByClassName(styles.screenReader)
      ).map((e) => e.getElementsByTagName('strong')[0])
      setTypeHereState(typeHere)
      setTypeTextState(typeText)
      setScreenReadersState(screenReaders)
      setInitTyping(true)
    }
  }, [initTyping])

  // Flashing Cursor
  React.useEffect(() => {
    if (slideLength) {
      setTimeout(() => {
        setCursorState((prev) => !prev)
      }, 530)
    }
  }, [cursorState])

  // Events to trigger on slide change
  React.useEffect(() => {
    if (initTyping && slideLength !== 0) {
      if (firstLoad) {
        setTimeout(() => {
          setActiveSlideIndex((prevIndex) => {
            return (prevIndex + 1) % slides.length
          })
        }, slideLength - fadeinLength)

        let slide = document.getElementsByClassName(styles.slide)[
          activeSlideIndex
        ]

        setTimeout(
          () => fadeOut(slide as HTMLElement, fadeoutLength),
          fadeoutStart - fadeinLength
        )

        if (typeTextState && screenReadersState && typeHereState) {
          let string: string[] = preProcessText(
            typeTextState[activeSlideIndex],
            screenReadersState[activeSlideIndex]
          )
          setTimeout(
            () =>
              doDelete(typeHereState[activeSlideIndex], string, deleteLength),
            slideLength - deleteLength - fadeinLength
          )
        }
        setFirstLoad(false)
        return
      }
      // swap slide after X seconds
      setTimeout(() => {
        setActiveSlideIndex((prevIndex) => {
          return (prevIndex + 1) % slides.length
        })
      }, slideLength)

      let slide = document.getElementsByClassName(styles.slide)[
        activeSlideIndex
      ]

      // set slide fade transition
      setTimeout(() => fadeIn(slide as HTMLElement, fadeinLength), fadeinStart)
      setTimeout(
        () => fadeOut(slide as HTMLElement, fadeoutLength),
        fadeoutStart
      )

      // do typing/delete animation
      if (typeTextState && screenReadersState && typeHereState) {
        let string: string[] = preProcessText(
          typeTextState[activeSlideIndex],
          screenReadersState[activeSlideIndex]
        )
        doTyping(typeHereState[activeSlideIndex], string, typingLength)
        setTimeout(
          () => doDelete(typeHereState[activeSlideIndex], string, deleteLength),
          slideLength - deleteLength
        )
      }
    }
  }, [activeSlideIndex, initTyping])

  return (
    <section
      className={`${styles.hero} ${
        isDarker ? 'backgroundDarker' : 'backgroundDark'
      }`}
    >
      <div className={styles.container}>
        {slides.map((slide, index) => (
          <>
            <div className={styles.textComponent} style={{pointerEvents: index === activeSlideIndex ? 'auto' : 'none'}}>
              <div className={`${styles.screenReader}`} aria-hidden={activeSlideIndex !== index}>
                <div>{slide.titleComponent}</div>
              </div>
              <div
                style={{ opacity: index === activeSlideIndex ? 1 : 0, pointerEvents: 'none' }}
                className={`${styles.title} ${
                  cursorState && index === activeSlideIndex ? styles.cursor : ''
                }`}
                aria-hidden="true"
                tabIndex={-1}
              >
                {slide.titleComponent}
              </div>
            </div>
            <div
              key={`slide-${index}`}
              style={{ opacity: index === 0 ? 1 : 0 }}
              className={styles.slide}
            >
              {checkImageIsSVG(slide.image?.url ?? '') ? (
                <img
                  src={slide.image?.url ?? undefined}
                  alt={slide.image?.alt ?? ''}
                  className={classNames(
                    styles.slideImage,
                    slide.imageSmall?.url &&
                      slide.image?.url &&
                      slide.imageSmall?.url?.split('?')[0] !==
                        slide.image?.url?.split('?')[0] &&
                      styles.image
                  )}
                />
              ) : ( slide.image?.gatsbyImageData && (
                <GatsbyImage
                  alt={slide.image?.alt ?? ''}
                  className={styles.imageWrapper}
                  image={slide.image?.gatsbyImageData ?? ''}
                  imgClassName={classNames(
                    styles.slideImage,
                    slide.imageSmall?.gatsbyImageData &&
                      slide.image?.gatsbyImageData &&
                      slide.imageSmall?.url?.split('?')[0] !==
                        slide.image?.url?.split('?')[0] &&
                      styles.image
                  )}
                  loading="eager"
                  objectFit="cover"
                  objectPosition="right"
                />
              ))}
              {slide.imageSmall?.url &&
                slide.image?.url &&
                slide.imageSmall?.url.split('?')[0] !==
                  slide.image?.url.split('?')[0] &&
                (checkImageIsSVG(slide.imageSmall.url) ? (
                  <img
                    src={slide.imageSmall?.url}
                    alt={slide.imageSmall?.alt ?? ''}
                    className={styles.imageSmall}
                  />
                ) : (
                  <GatsbyImage
                    alt={slide.imageSmall?.alt ?? ''}
                    className={styles.imageSmall}
                    image={slide.imageSmall?.gatsbyImageData ?? ''}
                    loading="eager"
                    objectFit="cover"
                  />
                ))}
            </div>
          </>
        ))}
      </div>
    </section>
  )
}
