import { BrandCollection } from './BrandCollection'
import { Highlights } from './Highlights'
import { Timeline } from './Timeline'
import { BrandOverview } from './BrandOverview'
import { TeamSlice } from './TeamSlice'
import { StatsHighlighterSlice } from './StatsHighlighter'
import { ValueHighlights } from './ValueHighlights'
import { BenefitsAndCulture } from './BenefitsAndCulture'
import { AdsProductServices } from './AdsProductServices'
import { ArticleParagraph } from './ArticleParagraph'
import { ArticleImage } from './ArticleImage'
import { ArticleQuote } from './ArticleQuote'
import { ArticleEmbed } from './ArticleEmbed'
import { JobListings } from './JobListings'
import { ArticleListSlice } from './ArticleListSlice'

export const aboutComponents = {
  article_list: ArticleListSlice,
  highlights_slice: Highlights,
  party: TeamSlice,
  timeline: Timeline,
}

export const advertisingComponents = {
  ads__products_and_services: AdsProductServices,
  article_list: ArticleListSlice,
  statshighlighter: StatsHighlighterSlice,
}

export const articleComponents = {
  article_list: ArticleListSlice,
  text_paragraph: ArticleParagraph,
  image: ArticleImage,
  quote: ArticleQuote,
  embed: ArticleEmbed,
}

export const brandsComponents = {
  article_list: ArticleListSlice,
  brand_collection: BrandCollection,
  highlights_slice: Highlights,
}

export const careersComponents = {
  article_list: ArticleListSlice,
  benefits_and_culture: BenefitsAndCulture,
  job_listings: JobListings,
  values_highlight: ValueHighlights,
}

export const homepageComponents = {
  article_list: ArticleListSlice,
  brands_overview: BrandOverview,
  highlights_slice: Highlights,
  statshighlighter: StatsHighlighterSlice,
}

export const allComponents = {
  ads__products_and_services: AdsProductServices,
  article_list: ArticleListSlice,
  benefits_and_culture: BenefitsAndCulture,
  brands_overview: BrandOverview,
  embed: ArticleEmbed,
  highlights_slice: Highlights,
  image: ArticleImage,
  job_listings: JobListings,
  party: TeamSlice,
  quote: ArticleQuote,
  statshighlighter: StatsHighlighterSlice,
  text_paragraph: ArticleParagraph,
  timeline: Timeline,
  values_highlight: ValueHighlights,
}

export interface SlicePageDataContext {
  bgStart: 'dark' | 'darker'
  otherSlices: Array<{ itemCount: number; slice_type: string }>
}

export const getSliceContextFromPageData = (doc: {
  body: ReadonlyArray<{ items?: ReadonlyArray<any>; slice_type: string }>
  slice_background_starting_color?: boolean | null
}): SlicePageDataContext => ({
  bgStart: doc.slice_background_starting_color ? 'darker' : 'dark',
  otherSlices: doc.body.map((slice) => ({
    itemCount: slice.items?.length ?? 0,
    slice_type: slice.slice_type,
  })),
})

export const getSliceBackgroundColor = (
  startingColor: 'dark' | 'darker',
  otherSlices: Array<{ itemCount: number; slice_type: string }>,
  sliceIndex: number
): 'dark' | 'darker' => {
  if (sliceIndex === 0) {
    return startingColor === 'dark' ? 'darker' : 'dark'
  } else {
    let trueIndex = sliceIndex

    const previousSlices = otherSlices
      ? otherSlices.filter((_, index) => index < sliceIndex)
      : []
    previousSlices.forEach((previousSlice) => {
      if (previousSlice.slice_type === 'timeline') {
        trueIndex += previousSlice.itemCount - 1
      } else if (previousSlice.slice_type === 'brands_overview') {
        trueIndex = trueIndex - 1
      }
    })

    const isEven = trueIndex % 2 === 0
    let color: 'dark' | 'darker'
    if (startingColor === 'dark') {
      color = isEven ? 'darker' : 'dark'
    } else {
      color = isEven ? 'dark' : 'darker'
    }
    return color
  }
}
