import { LinkResolverFunction } from '@prismicio/helpers'

export const linkResolver: LinkResolverFunction = (doc) => {
  switch (doc.type) {
    case 'blog_post': {
      return `/blog/${doc.uid}`
    }

    case 'about_page': {
      return `/about`
    }

    case 'blogs_page': {
      return `/blog`
    }

    case 'brands_page': {
      return `/brands`
    }

    case 'careers_page': {
      return `/careers`
    }

    case 'contact_page': {
      return `/contact`
    }

    case 'advertising_page': {
      return `/advertising`
    }

    default: {
      return '/'
    }
  }
}
