import * as React from 'react'

import classNames from 'classnames'

import { Stat } from './Stat'

import * as styles from './StatsHighlighter.module.scss'

interface StatsHighlighterProps {
  blurb: string
  className?: string
  ctaComponent?: JSX.Element
  hasDarkerBackgroundColor?: boolean
  layout: 'default' | 'alternate'
  stats: Array<{ label: string; value: string }>
  statsSource?: string
  title: string
}

export const StatsHighlighter = ({
  blurb,
  className,
  ctaComponent,
  hasDarkerBackgroundColor = false,
  layout = 'default',
  stats,
  statsSource,
  title,
}: StatsHighlighterProps) => {
  return (
    <section
      className={classNames(
        hasDarkerBackgroundColor ? 'backgroundDarker' : 'backgroundDark',
        className
      )}
    >
      <div
        className={classNames(
          styles.highlighter,
          layout === 'alternate' && styles.variant
        )}
      >
        <div className={styles.statsSliceContainer}>
          <div className={styles.textContainer}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.blurb}>{blurb}</div>
            {ctaComponent ? (
              <div className={styles.center}>{ctaComponent}</div>
            ) : null}
          </div>
          <div className={styles.statsContainer}>
            {stats.map((entry, index) => (
              <Stat
                key={index}
                value={entry.value}
                label={entry.label}
                large={layout === 'default'}
              />
            ))}
            {layout === 'default' && statsSource && (
              <div className={styles.source}>Source: {statsSource}</div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
