// extracted by mini-css-extract-plugin
export var backgroundDark = "Timeline-module--backgroundDark--zJaQY";
export var backgroundDarker = "Timeline-module--backgroundDarker--s5aDR";
export var blackbg = "Timeline-module--blackbg--uwl2T";
export var closingPoint = "Timeline-module--closingPoint--R6vlK";
export var contentContainer = "Timeline-module--content-container--OQO8D";
export var desc = "Timeline-module--desc--h6ieL";
export var first = "Timeline-module--first--ZI4Vh";
export var focusPoint = "Timeline-module--focusPoint--aGs9A";
export var fourtynineB = "Timeline-module--fourtynine-b--2s9Xq";
export var img = "Timeline-module--img--LwggC";
export var inputLabel = "Timeline-module--input-label--DlZiM";
export var left = "Timeline-module--left--2CSGM";
export var noDot = "Timeline-module--noDot--TYbtY";
export var openingPoint = "Timeline-module--openingPoint--g3ShR";
export var point = "Timeline-module--point--d2y1G";
export var pointContent = "Timeline-module--pointContent--GzJET";
export var right = "Timeline-module--right--mg-Wi";
export var seventysixB = "Timeline-module--seventysix-b--kKGSs";
export var sixteenB = "Timeline-module--sixteen-b--Ocj5R";
export var sixteenR = "Timeline-module--sixteen-r--yj-sq";
export var thirteenMK = "Timeline-module--thirteen-m-k--WumdS";
export var thirtynineB = "Timeline-module--thirtynine-b--eNatq";
export var thirtyoneB = "Timeline-module--thirtyone-b--Nc8ky";
export var timePoint = "Timeline-module--timePoint--8h41X";
export var timelineContainer = "Timeline-module--timelineContainer--v48gf";
export var timelineContainerMobile = "Timeline-module--timelineContainerMobile--Q1crG";
export var timelineContent = "Timeline-module--timelineContent--BuPQs";
export var timelineContentMobile = "Timeline-module--timelineContentMobile--Paepo";
export var timelineDot = "Timeline-module--timelineDot--6pExZ";
export var timelineLine = "Timeline-module--timelineLine--tb09W";
export var timelineTitle = "Timeline-module--timelineTitle--XDNgW";
export var title = "Timeline-module--title--2jQua";
export var twentyB = "Timeline-module--twenty-b--VaQjP";
export var twentyR = "Timeline-module--twenty-r--M0Bck";
export var twentyfiveB = "Timeline-module--twentyfive-b--C6cRl";