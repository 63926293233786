import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

interface SEOSocialMetaProps {
  facebook?: {
    description?: string | null
    image?: string | null
    title?: string | null
  }
  seo?: {
    description?: string | null
    title?: string | null
  }
  twitter?: {
    description?: string | null
    image?: string | null
    title?: string | null
  }
}

export const SEOSocialMeta = ({
  facebook,
  seo,
  twitter,
}: SEOSocialMetaProps) => {
  const globalData = useStaticQuery(graphql`
    query GlobalSEOSocialMetaSettings {
      prismicGlobalSeoSettings {
        data {
          default_facebook_description
          default_facebook_image {
            url
          }
          default_facebook_title
          default_seo_description
          default_seo_title
          default_twitter_description
          default_twitter_image {
            url
          }
          default_twitter_title
        }
      }
    }
    fragment AboutPageDataSEOSocialMeta on PrismicAboutPageDataType {
      facebook_description
      facebook_image {
        url
      }
      facebook_title
      seo_description
      seo_title
      twitter_description
      twitter_image {
        url
      }
      twitter_title
    }
    fragment AdvertisingPageDataSEOSocialMeta on PrismicAdvertisingPageDataType {
      facebook_description
      facebook_image {
        url
      }
      facebook_title
      seo_description
      seo_title
      twitter_description
      twitter_image {
        url
      }
      twitter_title
    }
    fragment ArticleDataSEOSocialMeta on PrismicArticleDataType {
      facebook_description
      facebook_image {
        url
      }
      facebook_title
      seo_description
      seo_title
      twitter_description
      twitter_image {
        url
      }
      twitter_title
    }
    fragment BlogPageDataSEOSocialMeta on PrismicBlogPageDataType {
      facebook_description
      facebook_image {
        url
      }
      facebook_title
      seo_description
      seo_title
      twitter_description
      twitter_image {
        url
      }
      twitter_title
    }
    fragment BrandsPageDataSEOSocialMeta on PrismicBrandsPageDataType {
      facebook_description
      facebook_image {
        url
      }
      facebook_title
      seo_description
      seo_title
      twitter_description
      twitter_image {
        url
      }
      twitter_title
    }
    fragment CareersPageDataSEOSocialMeta on PrismicCareersPageDataType {
      facebook_description
      facebook_image {
        url
      }
      facebook_title
      seo_description
      seo_title
      twitter_description
      twitter_image {
        url
      }
      twitter_title
    }
    fragment ContactPageDataSEOSocialMeta on PrismicContactPageDataType {
      facebook_description
      facebook_image {
        url
      }
      facebook_title
      seo_description
      seo_title
      twitter_description
      twitter_image {
        url
      }
      twitter_title
    }
    fragment HomePageDataSEOSocialMeta on PrismicHomePageDataType {
      facebook_description
      facebook_image {
        url
      }
      facebook_title
      seo_description
      seo_title
      twitter_description
      twitter_image {
        url
      }
      twitter_title
    }
    fragment JobListingDataSEOSocialMeta on PrismicJobListingDataType {
      facebook_description
      facebook_image {
        url
      }
      facebook_title
      seo_description
      seo_title
      twitter_description
      twitter_image {
        url
      }
      twitter_title
    }
    fragment GenericPageDataSEOSocialMeta on PrismicGenericPageDataType {
      facebook_description
      facebook_image {
        url
      }
      facebook_title
      seo_description
      seo_title
      twitter_description
      twitter_image {
        url
      }
      twitter_title
    }
  `)

  const seoTitle = seo?.title ?? globalData.default_seo_title
  const seoDescription = seo?.description ?? globalData.default_seo_title

  const twitterTitle =
    twitter?.title ?? globalData.default_twitter_title ?? seoTitle
  const twitterDescription =
    twitter?.description ??
    globalData.default_twitter_description ??
    seoDescription
  const twitterImage = twitter?.image ?? globalData.default_twitter_image?.url

  const facebookTitle =
    facebook?.title ?? globalData.default_facebook_title ?? seoTitle
  const facebookDescription =
    facebook?.description ??
    globalData.default_facebook_description ??
    seoDescription
  const facebookImage =
    facebook?.image ?? globalData.default_facebook_image?.url

  return (
    <Helmet>
      {seoTitle ? <title>{seoTitle}</title> : null}
      {seoDescription ? (
        <meta name="description" content={seoDescription} />
      ) : null}

      <meta name="twitter:card" content="summary_large_image" />
      {twitterTitle ? (
        <meta name="twitter:title" content={twitterTitle} />
      ) : null}
      {twitterDescription ? (
        <meta name="twitter:description" content={twitterDescription} />
      ) : null}
      {twitterImage ? (
        <meta name="twitter:image" content={twitterImage} />
      ) : null}

      <meta property="og:site_name" content="GAMURS Group" />
      {facebookTitle ? (
        <meta name="title" property="og:title" content={facebookTitle} />
      ) : null}
      {facebookDescription ? (
        <meta
          name="description"
          property="og:description"
          content={facebookDescription}
        />
      ) : null}
      {facebookImage ? (
        <meta property="og:image" content={facebookImage} />
      ) : null}
    </Helmet>
  )
}
