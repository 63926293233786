import * as React from 'react'
import { Link } from 'gatsby'

import * as styles from './SiteHeader.module.scss'

import GAMURSLogo from '../images/logos/logo-dark.svg'

export const SiteHeader = () => {
  const [menuState, setMenuState] = React.useState(false)
  const ref: React.LegacyRef<HTMLDivElement> = React.createRef()

  React.useEffect(() => {
    var prevScrollpos = window.pageYOffset
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset
      //scroll up past header, fix to top
      if (prevScrollpos > currentScrollPos && currentScrollPos > 80) {
        document.querySelector('header')!.classList.remove(styles.hide)
        document.querySelector('header')!.classList.add(styles.fixed)
        setTimeout(
          () =>
            document.querySelector('header')!.classList.add(styles.transition),
          10
        )
        // scroll down past header, hide
      } else if (prevScrollpos < currentScrollPos && currentScrollPos > 80) {
        document.querySelector('header')!.classList.remove(styles.fixed)
        document.querySelector('header')!.classList.add(styles.hide)
        setTimeout(
          () =>
            document.querySelector('header')!.classList.add(styles.transition),
          10
        )
      }
      // reset
      if (currentScrollPos === 0) {
        document.querySelector('header')!.classList.remove(styles.fixed)
        document.querySelector('header')!.classList.remove(styles.hide)
        document.querySelector('header')!.classList.remove(styles.transition)
      }
      prevScrollpos = currentScrollPos
    }

    if (ref.current) {
      ref.current.addEventListener('keydown', (k) => {
        if (k.key === 'Enter') {
          hamburgertoggle()
        }
      })
    }
  })

  function hamburgertoggle() {
    if (menuState) {
      setMenuState(false)
    } else {
      setMenuState(true)
    }
  }

  const links = [
    { name: 'About', link: '/about' },
    { name: 'Brands', link: '/brands' },
    { name: 'Advertising', link: '/advertising' },
    { name: 'Careers', link: '/careers' },
    { name: 'Blog', link: '/blog' },
    { name: 'Contact', link: '/contact' },
  ]

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <Link to="/" className={styles.logo}>
          <img alt="GAMURS logo" src={GAMURSLogo} />
        </Link>
        <div
          className={styles.hamburger}
          onClick={hamburgertoggle}
          tabIndex={0}
          role="navigation"
          ref={ref}
        >
          <i className={menuState ? 'icon-close' : 'icon-hamburger'} />
        </div>
        <nav
          className={`${menuState ? styles.open : styles.closed} ${
            styles.mobile
          }`}
        >
          <div className={styles.fakeBackground} />
          {links.map((e) => (
            <Link
              to={e.link}
              activeClassName={styles.activeNavLink}
              key={e.name}
              partiallyActive
            >
              {e.name}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  )
}
