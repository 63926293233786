import * as React from 'react'
import { graphql } from 'gatsby'
import * as styles from './ArticleSlices.module.scss'

interface ArticleEmbedProps {
  slice: Queries.PrismicArticleDataBodyEmbed
}

export const ArticleEmbed = ({ slice }: ArticleEmbedProps) => {
  return (
    <section className={`${styles.slice} ${styles.embed}`}>
      {slice.primary.embed && (
        <div dangerouslySetInnerHTML={{ __html: slice.primary.embed.html ?? '' }} />
      )}
      {slice.primary.embed__custom_ && (
        <div
          dangerouslySetInnerHTML={{
            __html: slice.primary.embed__custom_.text ?? '',
          }}
        />
      )}
    </section>
  )
}

export const query = graphql`
  fragment ArticleDataBodyEmbed on PrismicArticleDataBodyEmbed {
    id
    slice_type
    slice_label
    primary {
      embed {
        html
      }
      embed__custom_ {
        text
      }
    }
  }
  fragment GenericPageDataBodyEmbed on PrismicGenericPageDataBodyEmbed {
    id
    slice_type
    slice_label
    primary {
      embed {
        html
      }
      embed__custom_ {
        text
      }
    }
  }
`
