import * as React from 'react'

import { graphql, Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { getSliceBackgroundColor, SlicePageDataContext } from './index'

import DefaultMikeImage from '../images/mike/mike-job-default.svg'

import * as styles from './JobListings.module.scss'
import { checkImageIsSVG } from '../utils/urlUtils'

type LocationsDocumentDataProp = {
  locations: LocationDocumentWrapper[]
}
type LocationDocumentWrapper = {
  location: {
    document: {
      id: string
      data: {
        mike: {
          alt: string
          url: string
          gatsbyImageData: IGatsbyImageData
        }
        location_name: string
      }
    }
  }
}

interface ParsedJob {
  name: string
  location?: string
  role?: string
  id: string
}

interface ParsedLocation {
  location: string
  img: { alt: string; gatsbyImageData: IGatsbyImageData | null; url: string }
}
interface JobListingsSliceProps {
  slice: Queries.PrismicCareersPageDataBodyJobListings
  index: number
  context: SlicePageDataContext & { jobLocation: string }
}

export const JobListings = ({
  slice,
  index,
  context,
}: JobListingsSliceProps) => {
  const sliceBackgroundColor = getSliceBackgroundColor(
    context.bgStart,
    context.otherSlices,
    index
  )

  let locations: Array<ParsedLocation> =
    (
      slice.primary.locations?.document
        ?.data as unknown as LocationsDocumentDataProp
    ).locations?.map((e: LocationDocumentWrapper) => ({
      location: e.location.document.data.location_name,
      img: e.location.document.data.mike,
    })) ?? []
  locations.unshift({
    location: 'All locations',
    img: { url: DefaultMikeImage, alt: 'Mike', gatsbyImageData: null },
  })

  const [jobListings, setJobListings] = React.useState<
    Array<ParsedJob> | undefined
  >(undefined)
  const [jobLocation, setJobLocation] = React.useState(locations[0].location)

  const jobs: Array<ParsedJob> =
    slice.items[0].jobs?.document !== null
      ? slice.items.map((e) => ({
          name:
            (e.jobs?.document?.data as Queries.PrismicJobListingDataType)
              .position_title ?? '',
          location:
            (
              (e.jobs?.document?.data as Queries.PrismicJobListingDataType)
                .location?.document?.data as Queries.PrismicLocationDataType
            ).location_name ?? undefined,
          role:
            (e.jobs?.document?.data as Queries.PrismicJobListingDataType)
              .job_type ?? undefined,
          id: e.jobs?.document?.id ?? '',
        }))
      : []

  const listingsRef = React.useRef(null)

  if (jobListings === undefined) {
    setJobListings(jobs)
  }
  function setLocation(location: string) {
    setJobLocation(location)
    if (location === locations[0].location) {
      setJobListings(jobs)
      return
    }
    setJobListings(jobs.filter((job) => job.location === location))
  }
  const ref: React.LegacyRef<HTMLDivElement> = React.createRef()

  React.useEffect(() => {
    // Make location filters keyboard accessible
    if (ref.current) {
      Array.from(ref.current.children).forEach((e: Element) => {
        e.addEventListener('keydown', (k: Event) => {
          if ((k as KeyboardEvent).key === 'Enter') {
            setLocation((e as HTMLElement).innerText)
          }
        })
      })
    }

    if (context.jobLocation) {
      setLocation(context.jobLocation)
      if (listingsRef.current) {
        (listingsRef.current as HTMLDivElement).scrollIntoView()
      }
    }
  }, [])

  return (
    <section
      className={`${
        sliceBackgroundColor === 'darker'
          ? 'backgroundDarker'
          : 'backgroundDark'
      }`}
      id="listings"
    >
      <div className={styles.container} ref={listingsRef}>
        <div className={styles.selector}>
          <div className={styles.location}>
            <div className={styles.locationContainer}>
              <h2>Location</h2>
              <nav ref={ref}>
                {locations.map((location) => (
                  <div
                    key={location.location}
                    onClick={() => setLocation(location.location)}
                    className={
                      jobLocation === location.location ? styles.highlight : ''
                    }
                    tabIndex={0}
                    role={'button'}
                  >
                    {location.location}
                  </div>
                ))}
              </nav>
            </div>
          </div>
          <figure className={styles.mike}>
            <img alt="Mike Default" src={DefaultMikeImage} aria-hidden />
            {locations.map((location: ParsedLocation) =>
              checkImageIsSVG(location.img.url) ? (
                <img
                  src={location.img.url}
                  alt={location.img.alt ?? ''}
                  className={
                    jobLocation === location.location ? '' : styles.hide
                  }
                />
              ) : (
                location.img.gatsbyImageData && (
                  <GatsbyImage
                    image={location.img.gatsbyImageData}
                    alt={location.img.alt ?? ''}
                    key={location.location}
                    aria-hidden
                    className={
                      jobLocation === location.location ? '' : styles.hide
                    }
                  />
                )
              )
            )}
          </figure>
        </div>
        <div className={styles.listings}>
          <div className={styles.listingsContainer}>
            <h1>Job listings</h1>
            <div className={styles.joblist}>
              {jobListings?.length === 0 && (
                <>
                  <div className={styles.noListings}>
                    {slice.primary.no_listings_message}
                  </div>
                  <Link
                    className={styles.button}
                    to={slice.primary.cta_link_no_listings.url}
                  >
                    {slice.primary.cta_text_no_listings}
                  </Link>
                </>
              )}
              {jobListings &&
                jobListings.map((job) => (
                  <div key={job.id} className={styles.job}>
                    <Link
                      to={`/careers/${job.id}`}
                      state={{ jobLocation: jobLocation }}
                    >
                      <h2>{job.name}</h2>
                      {(job.location || job.role) && (
                        <h3>
                          {job.location}
                          {job.location && job.role
                            ? `, ${job.role}`
                            : job.role}
                        </h3>
                      )}
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CareersPageDataBodyJobListings on PrismicCareersPageDataBodyJobListings {
    id
    slice_label
    slice_type
    primary {
      no_listings_message
      cta_text_no_listings
      cta_link_no_listings {
        url
      }
      locations {
        document {
          ... on PrismicLocationsGroup {
            id
            data {
              locations {
                location {
                  document {
                    ... on PrismicLocation {
                      id
                      data {
                        mike {
                          gatsbyImageData(placeholder: BLURRED)
                          url
                          alt
                        }
                        location_name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    items {
      jobs {
        document {
          ... on PrismicJobListing {
            id
            data {
              application_link {
                url
              }
              position_title
              location {
                document {
                  ... on PrismicLocation {
                    id
                    data {
                      location_name
                    }
                  }
                }
              }
              job_type
            }
          }
        }
      }
    }
  }
  fragment GenericPageDataBodyJobListings on PrismicGenericPageDataBodyJobListings {
    id
    slice_label
    slice_type
    primary {
      no_listings_message
      cta_text_no_listings
      cta_link_no_listings {
        url
      }
      locations {
        document {
          ... on PrismicLocationsGroup {
            id
            data {
              locations {
                location {
                  document {
                    ... on PrismicLocation {
                      id
                      data {
                        mike {
                          gatsbyImageData(placeholder: BLURRED)
                          url
                          alt
                        }
                        location_name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    items {
      jobs {
        document {
          ... on PrismicJobListing {
            id
            data {
              application_link {
                url
              }
              position_title
              location {
                document {
                  ... on PrismicLocation {
                    id
                    data {
                      location_name
                    }
                  }
                }
              }
              job_type
            }
          }
        }
      }
    }
  }
`
