import * as React from 'react'
import { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { ContactForm } from '../components/ContactForm'
import { Layout } from '../components/Layout'

import * as styles from './contact.module.scss'
import { SEOSocialMeta } from '../components/SEOSocialMeta'
import classNames from 'classnames'

const ContactPage = ({ data }: PageProps<Queries.ContactPageQuery>) => {
  if (!data || !data.prismicContactPage) return null
  const doc = data.prismicContactPage.data

  const [email, setEmail] = React.useState('')

  React.useEffect(() => {
    const text = [
      'm',
      'o',
      'c',
      '.',
      's',
      'r',
      'u',
      'm',
      'a',
      'g',
      '@',
      't',
      'c',
      'a',
      't',
      'n',
      'o',
      'c',
    ]
    setEmail(text.reverse().join(''))
  })

  return (
    <Layout>
      <SEOSocialMeta
        facebook={{
          description: doc.facebook_description,
          image: doc.facebook_image?.url,
          title: doc.facebook_title,
        }}
        seo={{
          description: doc.seo_description,
          title: doc.seo_title,
        }}
        twitter={{
          description: doc.twitter_description,
          image: doc.twitter_image?.url,
          title: doc.twitter_title,
        }}
      />
      <section className={styles.contact}>
        <div className={styles.content}>
          <div className={styles.contactText}>
            <h2 className={styles.pageTitle}>{doc.page_label ?? 'Contact'}</h2>
            <h1 className={styles.titletext}>{doc.heading}</h1>
            <div
              className={styles.blurb}
              dangerouslySetInnerHTML={{
                __html: (doc.blurb ? doc.blurb.html : '') as string,
              }}
            />
            <h2
              className={classNames(
                'icon-mail',
                styles.emailtext,
                'filter-ash'
              )}
            >
              {email}
            </h2>
          </div>
          <div className={styles.contactFormWrapper}>
            <ContactForm />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ContactPage {
    prismicContactPage {
      _previewable
      data {
        ...ContactPageDataSEOSocialMeta
        page_label
        blurb {
          html
        }
        heading
      }
    }
  }
`

export default withPrismicPreview(ContactPage)
