import * as React from 'react'
import * as styles from './ArticleTile.module.scss'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { checkImageIsSVG } from '../utils/urlUtils'
import classNames from 'classnames'

type ArticleTileProps = {
  featured?: boolean
  image: { url: string; alt: string; gatsbyImageData: IGatsbyImageData } | null
  slug: string
  title: string
}

export const ArticleTile = ({
  image,
  featured = false,
  slug,
  title,
}: ArticleTileProps) => {
  const imageClassName = classNames(styles.image, featured && styles.featured)
  return (
    <a href={`/blog/${slug}`}>
      <article className={styles.wrapper}>
        <div className={imageClassName}>
          {image ? (
            checkImageIsSVG(image.url) ? (
              <img
                className={imageClassName}
                src={image.url}
                alt={image.alt ?? ''}
              />
            ) : (
              <GatsbyImage
                className={imageClassName}
                image={image.gatsbyImageData ?? null}
                objectFit="cover"
                alt={image.alt ?? ''}
              />
            )
          ) : null}
        </div>
        <div className={styles.title}>{title}</div>
      </article>
    </a>
  )
}
