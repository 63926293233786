import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

import { getSliceBackgroundColor, SlicePageDataContext } from './index'
import { ArticleList } from '../components/ArticleList'

import * as styles from './ArticleListSlice.module.scss'

interface ArticleListSliceProps {
  slice:
    | Queries.PrismicAboutPageDataBodyArticleList
    | Queries.PrismicAdvertisingPageDataBodyArticleList
    | Queries.PrismicBrandsPageDataBodyArticleList
    | Queries.PrismicCareersPageDataBodyArticleList
    | Queries.PrismicHomePageDataBodyArticleList
  index: number
  context: SlicePageDataContext
}

export const ArticleListSlice = ({
  slice,
  index,
  context,
}: ArticleListSliceProps) => {
  const sliceBackgroundColor = getSliceBackgroundColor(
    context.bgStart,
    context.otherSlices,
    index
  )
  const queryData = useStaticQuery(graphql`
    query RecentArticles {
      allPrismicArticle(
        sort: { fields: first_publication_date, order: DESC }
        limit: 3
      ) {
        nodes {
          uid
          data {
            title {
              text
            }
            featured_image {
              gatsbyImageData(placeholder: BLURRED)
              url
              alt
            }
          }
        }
      }
    }
  `)
  const extractArticleData = (article: any) => ({
    title: article.data.title.text,
    featuredImage: article.data.featured_image,
    slug: article.uid,
  })
  const recentArticles =
    queryData.allPrismicArticle.nodes.map(extractArticleData)
  let articles = slice.items
    ? slice.items.map((article: any) =>
        extractArticleData(article.article.document)
      )
    : []
  articles = [
    ...articles,
    ...recentArticles.filter(
      (e: any) => !articles.map((i: any) => i.slug).includes(e.slug)
    ),
  ]
  return (
    <section
      className={`${styles.articleListSlice} ${
        sliceBackgroundColor === 'darker'
          ? 'backgroundDarker'
          : 'backgroundDark'
      }`}
    >
      <h1>{slice.primary.sliceTitle?.text}</h1>
      <div className={styles.blurb}>
        <PrismicRichText field={slice.primary.blurb?.richText} />
      </div>
      <ArticleList articles={articles} limit={3} />
    </section>
  )
}

export const query = graphql`
  fragment AboutPageDataBodyArticleList on PrismicAboutPageDataBodyArticleList {
    id
    slice_type
    slice_label
    primary {
      blurb {
        richText
      }
      sliceTitle: title {
        text
      }
    }
    items {
      article {
        document {
          ... on PrismicArticle {
            id
            uid
            data {
              title {
                text
              }
              featured_image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
            }
          }
        }
      }
    }
  }
  fragment AdvertisingPageDataBodyArticleList on PrismicAdvertisingPageDataBodyArticleList {
    id
    slice_type
    slice_label
    primary {
      blurb {
        richText
      }
      sliceTitle: title {
        text
      }
    }
    items {
      article {
        document {
          ... on PrismicArticle {
            id
            uid
            data {
              title {
                text
              }
              featured_image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
            }
          }
        }
      }
    }
  }
  fragment BrandsPageDataBodyArticleList on PrismicBrandsPageDataBodyArticleList {
    id
    slice_type
    slice_label
    primary {
      blurb {
        richText
      }
      sliceTitle: title {
        text
      }
    }
    items {
      article {
        document {
          ... on PrismicArticle {
            id
            uid
            data {
              title {
                text
              }
              featured_image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
            }
          }
        }
      }
    }
  }
  fragment CareersPageDataBodyArticleList on PrismicCareersPageDataBodyArticleList {
    id
    slice_type
    slice_label
    primary {
      blurb {
        richText
      }
      sliceTitle: title {
        text
      }
    }
    items {
      article {
        document {
          ... on PrismicArticle {
            id
            uid
            data {
              title {
                text
              }
              featured_image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
            }
          }
        }
      }
    }
  }
  fragment HomePageDataBodyArticleList on PrismicHomePageDataBodyArticleList {
    id
    slice_type
    slice_label
    primary {
      blurb {
        richText
      }
      sliceTitle: title {
        text
      }
    }
    items {
      article {
        document {
          ... on PrismicArticle {
            id
            uid
            data {
              title {
                text
              }
              featured_image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
            }
          }
        }
      }
    }
  }
  fragment GenericPageDataBodyArticleList on PrismicGenericPageDataBodyArticleList {
    id
    slice_type
    slice_label
    primary {
      blurb {
        richText
      }
      sliceTitle: title {
        text
      }
    }
    items {
      article {
        document {
          ... on PrismicArticle {
            id
            uid
            data {
              title {
                text
              }
              featured_image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
            }
          }
        }
      }
    }
  }
`
