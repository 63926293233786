import * as React from 'react'
import classNames from 'classnames'

import * as styles from './PageHero.module.scss'
interface PageHeroProps {
  label?: string
  titleComponent?: JSX.Element | null
  title?: string
  centerComponent?: JSX.Element | null
  blurb?: string
  ctaComponent?: JSX.Element | null
  isDarker: boolean | null
}

export const PageHero = ({
  label,
  titleComponent,
  title,
  centerComponent,
  blurb,
  ctaComponent,
  isDarker = false,
}: PageHeroProps) => (
  <section
    className={classNames(
      styles.container,
      isDarker ? 'backgroundDarker' : 'backgroundDark'
    )}
  >
    <div className={styles.content}>
      {label ? <div className={styles.label}>{label}</div> : null}
      <div className={styles.title}>
        {titleComponent}
        {title}
      </div>
      {blurb ? <div className={styles.blurb}>{blurb}</div> : null}
      {ctaComponent ? <div className={styles.cta}>{ctaComponent}</div> : null}
      {centerComponent}
    </div>
  </section>
)
