/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */

import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from './linkResolver'

import AboutPage from '../pages/about'
import AdvertisingPage from '../pages/advertising'
import BrandsPage from '../pages/brands'
import CareersPage from '../pages/careers'
import ContactPage from '../pages/contact'
import HomePage from '../pages/index'

import ArticleTemplate from '../templates/article'

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      article: ArticleTemplate,
      about_page: AboutPage,
      advertising_page: AdvertisingPage,
      brands_page: BrandsPage,
      careers_page: CareersPage,
      contact_page: ContactPage,
      home_page: HomePage,
    }),
  },
]
