// extracted by mini-css-extract-plugin
export var card = "EmployeeProfile-module--card--8WbpA";
export var fourtynineB = "EmployeeProfile-module--fourtynine-b--sgwnx";
export var infobox = "EmployeeProfile-module--infobox--J37ep";
export var inputLabel = "EmployeeProfile-module--input-label--UCnJp";
export var links = "EmployeeProfile-module--links--v1nL5";
export var name = "EmployeeProfile-module--name--MG7PU";
export var profile = "EmployeeProfile-module--profile--KhuOQ";
export var seventysixB = "EmployeeProfile-module--seventysix-b--MiXXA";
export var sixteenB = "EmployeeProfile-module--sixteen-b--bGXzp";
export var sixteenR = "EmployeeProfile-module--sixteen-r--PcNzB";
export var socials = "EmployeeProfile-module--socials--Q2zDr";
export var thirteenMK = "EmployeeProfile-module--thirteen-m-k--sYv3k";
export var thirtynineB = "EmployeeProfile-module--thirtynine-b--Hb25P";
export var thirtyoneB = "EmployeeProfile-module--thirtyone-b--x4XYm";
export var title = "EmployeeProfile-module--title--tUOCl";
export var twentyB = "EmployeeProfile-module--twenty-b--Fh48x";
export var twentyR = "EmployeeProfile-module--twenty-r--JPgQH";
export var twentyfiveB = "EmployeeProfile-module--twentyfive-b--1syJC";